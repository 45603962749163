import { Autocomplete, Box, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useLazyGetCustomerQuery } from "../../../services/API/pricingRuleAPI";

interface IProps {
  disabled?: boolean;
  customer: ICustomers | null;
  setCustomer: Dispatch<SetStateAction<ICustomers | null>>;
}

const SearchableCustomerDDSS = ({
  disabled = false,
  customer = null,
  setCustomer,
}: IProps) => {
  const [query, setQuery] = useState<string>("");
  const [options, setOptions] = useState<ICustomers[]>([]);

  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId
  );

  /*
   * API
   * */

  const [getCustomer, { data: customerData = [], isFetching }] =
    useLazyGetCustomerQuery();

  /*
   * Effects
   * */

  useEffect(() => {
    if (query.length >= 3) {
      getCustomer({
        companyDealerId,
        Search: query,
      });
    }
  }, [query]);

  // Update options whenever customer changes
  useEffect(() => {
    setOptions(customerData);
  }, [customerData]);

  /*
   * Handler
   * */

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: ICustomers | null
  ) => {
    debugger
    setCustomer(newValue);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Autocomplete
        sx={{ width: "calc(100%)" }}
        disabled={disabled}
        options={options}
        loading={isFetching}
        getOptionLabel={(option: ICustomers) => option?.organizationName}
        value={customer}
        onChange={handleSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Customer"
            variant="outlined"
            onChange={handleInputChange}
            required={true}
            size="small"
          />
        )}
      />
    </Box>
  );
};

export default SearchableCustomerDDSS;
