import { useState } from "react"
import {
  Button,
  Container,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch } from "../../app/hooks"
import { PRICEING_RULES } from "../../constants/routeConstants"
import { setCredentials, setDmsType } from "../../features/auth/authSlice"
import { decodeToken, getProfileFromToken } from "../../helpers/helpers"
import Header from "../../layouts/simple/header"
import { useLoginMutation } from "../../services/API/accountsAPI"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [form, setForm] = useState<LoginRequest>({
    dmsDealerId: undefined,
    username: "",
    password: "",
  })

  // Login Mutation
  const [login, { isLoading }] = useLoginMutation()

  /*
   * API.
   * */

  const getUser = async (token: string, userId: string) => {
    const IDMS_BASE_URL = import.meta.env.VITE_IDMS_BASE_URL

    const apiUrl = `${IDMS_BASE_URL}User?UserId=${userId}&IncludeUserDms=${true}`

    fetch(apiUrl, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Fetching DMS Type Failed")
        }
        return response.json()
      })
      .then((jsonData) => {
        const userData = jsonData?.data[0]

        if (userData && userData.userDms.length > 0) {
          dispatch(
            setDmsType({
              dmsType: jsonData?.data[0]?.userDms[0]?.everestDmsOrder,
            }),
          )
        } else {
          throw new Error("DMS Type doesn't exist in response")
        }
      })
      .catch((error) => {
        console.error("Error:", error)
        toast.error(`Error: ${error}`)
      })
  }

  /*
   * Handlers
   * */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    await login(form)
      .unwrap()
      .then((response) => {
        dispatch(
          setCredentials({
            access_token: response.access_token,
            expires_in: response.expires_in,
            refresh_token: response.refresh_token,
            token_type: response.token_type,
            scope: response.scope,
            username: decodeToken(response.access_token).name,
            dealerId: getProfileFromToken(response.access_token).dealerId,
            dmsDealerId: getProfileFromToken(response.access_token)
              .everestDmsDealerId,
            companyDealerId: getProfileFromToken(response.access_token)
              .companyDealerId,
            companyName: getProfileFromToken(response.access_token).companyName,
          }),
        )

        // Get User API Call
        getUser(response.access_token, decodeToken(response.access_token).sub)

        toast.success("Logged in successfully")

        // navigate to dashboard
        setTimeout(() => {
          navigate(PRICEING_RULES)
        }, 500)
      })
      .catch((error) => {
        console.error("Invalid Credentials", error)
        toast.error("Invalid Credentials")
      })
  }

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <Header />
      <Toolbar />
      <Toolbar />

      <Container>
        <Grid container>
          {/* Left Empty Grid */}
          <Grid item xs={12} md={4} />

          {/* Center Grid */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" textAlign={"center"} gutterBottom>
              Login
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                sx={{ mb: "10px" }}
                label="Dms Dealer Id"
                name="dmsDealerId"
                required
                value={form.dmsDealerId}
                onChange={handleChange}
                size="small"
                type="text"
                placeholder="Dms Dealer Id"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                fullWidth
                sx={{ mb: "10px" }}
                label="Username"
                name="username"
                required
                value={form.username}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
                placeholder="Username"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                fullWidth
                sx={{ mb: "15px" }}
                label="Password"
                name="password"
                required
                value={form.password}
                onChange={handleChange}
                size="small"
                type="password"
                variant="outlined"
                placeholder="Password"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {isLoading ? (
                <Button
                  endIcon={<CircularProgress size="1.5rem" />}
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  Logging in...
                </Button>
              ) : (
                <Button fullWidth variant="contained" type="submit">
                  Login
                </Button>
              )}
            </form>
          </Grid>

          {/* Right Empty Grid */}
          <Grid item xs={12} md={4} />
        </Grid>
      </Container>
    </>
  )
}

export default Login
