/* eslint-disable prettier/prettier */
import { FC } from "react"
import { Box, IconButton, LinearProgress, TablePagination } from "@mui/material"
import {
  GridRowSelectionModel,
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import LastPageIcon from "@mui/icons-material/LastPage"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import CustomFilterPanel from "./CustomFilterPanel"
import {
  UserFilterColumn,
  FilterOperator,
  RoleFilterColumn,
  ProductFilterColumn,
} from "../../constants/enums"
import { CustomToolbar } from "./CustomToolbar"
import { useDensity } from "../../features/gridSlice/gridSlice"

export interface ServerGridProps {
  rows: any[]
  columns: GridColDef<any, any, any>[]
  rowCount?: number
  isLoading?: boolean
  onFilterChange?: (model: GridFilterModel) => void
  filterColumn?: UserFilterColumn | RoleFilterColumn | ProductFilterColumn | any
  setFilterColumn?: (value: any) => void
  filterOperator?: FilterOperator
  setFilterOperator?: (value: FilterOperator) => void
  filterValue?: string
  setFilterValue?: (value: string) => void
  onSortModelChange?: (model: GridSortModel) => void
  paginationModel?: PaginationModel
  setPaginationModel?: (model: PaginationModel) => void
  rowSelectionModel?: GridRowSelectionModel
  setRowSelectionModel?: (model: GridRowSelectionModel) => void
  pageNumbers?: number[][]
  totalPages?: number
  keepNonExistentRowsSelected?: boolean
  getRowHeight?:
    | ((params: GridRowHeightParams) => GridRowHeightReturnValue)
    | undefined
  hiddenColumns?: string[] // Hide columns by default
  columnVisibilityModel?: GridColumnVisibilityModel
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void
  disableColumnFilter?: boolean
}

const ServerGrid: FC<ServerGridProps> = (props) => {
  const density = useDensity()

  return (
    <>
      {!props?.isLoading && props.rows && props.columns.length > 0 ? (
        <DataGrid
          rows={props.rows}
          columns={
            props?.hiddenColumns && props.hiddenColumns.length > 0
              ? props.columns.filter(
                  (column) => !props.hiddenColumns?.includes(column.field),
                )
              : props.columns
          }
          autoHeight
          getRowHeight={props?.getRowHeight}
          columnVisibilityModel={props?.columnVisibilityModel}
          onColumnVisibilityModelChange={props?.onColumnVisibilityModelChange}
          sortingMode={props?.onSortModelChange ? "server" : "client"}
          onSortModelChange={props?.onSortModelChange}
          filterMode={props?.onFilterChange ? "server" : "client"}
          onFilterModelChange={props?.onFilterChange}
          pagination
          paginationMode={props?.paginationModel ? "server" : "client"}
          paginationModel={props?.paginationModel}
          rowCount={props?.rowCount}
          pageSizeOptions={[15, 30, 50, 100]}
          onPaginationModelChange={props?.setPaginationModel}
          rowSelectionModel={props?.rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            props?.setRowSelectionModel &&
              props.setRowSelectionModel(newRowSelectionModel)
          }}
          loading={props?.isLoading}
          keepNonExistentRowsSelected={
            props?.keepNonExistentRowsSelected || false
          }
          disableColumnMenu
          disableColumnFilter={props.disableColumnFilter || false}
          density={density}
          slots={{
            toolbar: CustomToolbar,

            ...(props?.onFilterChange !== undefined &&
              props?.filterColumn &&
              props?.setFilterColumn !== undefined &&
              props?.filterOperator &&
              props?.setFilterOperator !== undefined &&
              props?.filterValue !== undefined &&
              props?.setFilterValue !== undefined && {
                filterPanel: () => (
                  <CustomFilterPanel
                    columns={props.columns
                      .filter((column) => column.filterable)
                      .map((column) => column.field)}
                    applyFilters={props.onFilterChange!}
                    filterColumn={props.filterColumn!}
                    setFilterColumn={props.setFilterColumn!}
                    filterOperator={props.filterOperator!}
                    setFilterOperator={props.setFilterOperator!}
                    filterValue={props.filterValue!}
                    setFilterValue={props.setFilterValue!}
                  />
                ),
              }),

            ...(props?.paginationModel !== undefined &&
              props?.paginationModel?.page !== undefined &&
              props?.paginationModel?.pageSize !== undefined &&
              props?.rowCount &&
              props?.setPaginationModel !== undefined &&
              props?.pageNumbers &&
              props?.totalPages && {
                pagination: () => (
                  <TablePagination
                    component={"div"}
                    color="primary"
                    showFirstButton
                    showLastButton
                    count={props.rowCount! || 0}
                    page={props.paginationModel!.page}
                    rowsPerPage={props.paginationModel!.pageSize}
                    rowsPerPageOptions={[15, 30, 50, 100]}
                    onPageChange={(e, page) => {
                      props.setPaginationModel!({
                        ...props.paginationModel!,
                        page: page,
                      })
                    }}
                    onRowsPerPageChange={(e) => {
                      props.setPaginationModel!({
                        pageSize: parseInt(e.target.value, 10),
                        page: 0, // go back to first page on page size change
                      })
                    }}
                    ActionsComponent={({ onPageChange }) => (
                      <Box
                        sx={{
                          display: "flex",
                          marginLeft: "2rem",
                        }}
                      >
                        <IconButton
                          onClick={() => onPageChange(null, 0)}
                          disabled={props.paginationModel!.page === 0}
                          title="First Page"
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            onPageChange(null, props.paginationModel!.page - 1)
                          }
                          disabled={props.paginationModel!.page === 0}
                          title="Previous Page"
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        {props.pageNumbers!.map((pages, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                            }}
                          >
                            {pages?.map((page) => (
                              <IconButton
                                key={page}
                                onClick={() => onPageChange(null, page - 1)}
                                disabled={
                                  props.paginationModel!.page === page - 1
                                }
                                color={
                                  props.paginationModel!.page === page - 1
                                    ? "primary"
                                    : "default"
                                }
                                sx={{
                                  fontSize: "1rem",
                                }}
                              >
                                {page}
                              </IconButton>
                            ))}
                            {index !== props.pageNumbers!.length - 1 && (
                              <IconButton disabled color="primary">
                                ...
                              </IconButton>
                            )}
                          </Box>
                        ))}
                        <IconButton
                          onClick={() =>
                            onPageChange(null, props.paginationModel!.page + 1)
                          }
                          disabled={
                            props.paginationModel!.page ===
                            props.totalPages! - 1
                          }
                          title="Next Page"
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            onPageChange(null, props.totalPages! - 1)
                          }
                          disabled={
                            props.paginationModel!.page ===
                            props.totalPages! - 1
                          }
                          title="Last Page"
                        >
                          <LastPageIcon />
                        </IconButton>
                      </Box>
                    )}
                  />
                ),
              }),
          }}
          sx={{
            bgcolor: "white",
            borderRadius: 7,
            filter: props?.isLoading ? "blur(1px)" : "none",
            transition: "filter 0.3s ease",
            "& .MuiDataGrid-columnHeader": {
              bgcolor: "#eaecee",
            },
          }}
        />
      ) : (
        <LinearProgress />
      )}
    </>
  )
}
export default ServerGrid
