import jwt_decode from "jwt-decode"
import { useNavigate } from "react-router-dom"

interface ACCESS_TOKEN {
  exp: number
  iss: string
  aud: string
  client_id: string
  sub: string
  auth_time: number
  idp: string
  role: string
  name: string
  given_name: string
  profile: string
  jti: string
  iat: number
  scope: string[]
  amr: string[]
}

// Decode Token:
export const decodeToken = (token: any) => {
  const decodedJwt: ACCESS_TOKEN = jwt_decode(token)
  return decodedJwt
}

// Get Profile Object From Token
export const getProfileFromToken = (token: any) => {
  const decodedJwt = decodeToken(token)
  const profileObj = JSON.parse(decodedJwt.profile)
  return profileObj
}

export const arrayToCommaSeparatedString = (arr: any) => {
  return arr.join(",")
}

export const commaSeparatedStringToArray = (str: any) => {
  if (!str) return []
  return str.split(",").map((item: any) => item.trim())
}

export const commaSeparatedStringToNumberArray = (str: any): number[] => {
  if (!str) return []
  return str.split(",").map((item: any) => parseInt(item.trim(), 10))
}

// Helper method to convert PascalCase to "First Last" format
export const formatUserName = (input: string): string => {
  // Regular expression to insert a space before each capital letter or number
  // return input.replace(/([a-z])([A-Z])/g, "$1 $2")
  return input.replace(/([a-z])([A-Z0-9])/g, "$1 $2")
}

export const findQuantityDiscountById = (quantityDiscounts: any, id: any) => {
  return quantityDiscounts?.find((qd: any) => qd.id === id)
}

export function isOnlyWholegoodInArray(arr: string[]): boolean {
  return arr.length === 1 && arr[0] === "WholeGood"
}

export const findPriceRangeById = (priceRanges: any, priceRangeId: any) => {
  return priceRanges?.find((pr: any) => pr.priceRangeId === priceRangeId)
}

export const getInventoryPartIds = (
  arr: { inventoryPartId: string }[],
): string => {
  if (!arr || arr.length === 0) return ""

  const inventoryPartIds = arr.map((obj) => obj.inventoryPartId).join(",")
  return inventoryPartIds
}
