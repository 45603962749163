import { Box, Container, Typography } from "@mui/material"
import { Helmet } from "react-helmet-async"

const Rebates = () => {
  return (
    <>
      <Helmet>
        <title> Rebates </title>
      </Helmet>

      <Container>
        <Typography variant="h6" gutterBottom>
          Rebates
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="70vh"
        >
          <Typography variant="h2" gutterBottom>
            Coming Soon
          </Typography>
        </Box>
      </Container>
    </>
  )
}

export default Rebates
