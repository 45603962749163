import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useLazyGetUnitQuery } from "../../../services/API/pricingRuleAPI"

interface Props {
  disabled: boolean
  inventoryPartIds: string
  manufacturerList: string[]
  selectedUnit: UnitsData[]
  setSelectedUnit: Dispatch<SetStateAction<UnitsData[]>>
}

const SearchableUnitDD = ({
  disabled = false,
  inventoryPartIds,
  manufacturerList = [],
  selectedUnit = [],
  setSelectedUnit,
}: Props) => {
  const [query, setQuery] = useState<string>("")

  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )

  /*
   * API
   * */

  const [getUnit, { data: unitsData = [], isFetching }] = useLazyGetUnitQuery()

  /*
   * Effects
   * */

  useEffect(() => {
    if (query.length >= 3) {
      getUnit({
        CompanyDealerId: companyDealerId,
        Manufacturer: manufacturerList,
        InventoryModelId: inventoryPartIds,
        Search: query,
      })
    }
  }, [query])

  /*
   * Handler
   * */

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: UnitsData[],
  ) => {
    setSelectedUnit(newValue)
  }

  return (
    <Autocomplete
      sx={{ width: "calc(100% - 290px)" }}
      multiple
      options={unitsData}
      disabled={disabled}
      disableCloseOnSelect
      getOptionLabel={(option: UnitsData) =>
        option?.lineCode +
        " - " +
        option?.modelNumber +
        " - " +
        option?.serialNumber
      }
      value={selectedUnit}
      loading={isFetching}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Unit # / Serial #"
          variant="outlined"
          onChange={handleInputChange}
        />
      )}
    />
  )
}

export default SearchableUnitDD
