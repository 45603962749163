export function convertObjectToQueryParams(obj: Record<string, any>): string {
  const queryParams = new URLSearchParams();

  // Iterate over each property in obj and add it to the queryParams object
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      const value = obj[property];

      if (value !== undefined) {
        queryParams.set(property, value.toString());
      }
    }
  }

  return queryParams.toString();
}
