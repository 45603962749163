import { Box, Divider, Typography } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import DashboardIcon from "@mui/icons-material/Dashboard"
import SettingsIcon from "@mui/icons-material/Settings"
import PriceChangeIcon from "@mui/icons-material/PriceChange"
import CalculateIcon from "@mui/icons-material/Calculate"
import DiscountIcon from "@mui/icons-material/Discount"
import CardMembershipIcon from "@mui/icons-material/CardMembership"
import { NavLink as RouterLink } from "react-router-dom"
import { ListItemIcon, ListItemButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  APP,
  CALCULATE_PRICE,
  LOYALTY,
  PRICEING_RULES,
  REBATES,
  RULES_SETTINGS,
} from "../../../constants/routeConstants"

const drawerWidth = 240

interface Props {
  window?: () => Window
  handleDrawerToggle: () => void
  mobileOpen: boolean
}

const Nav = (props: Props) => {
  const classes = useStyles()
  const { window, handleDrawerToggle, mobileOpen } = props

  const navConfig = [
    {
      title: "Pricing Dashboard",
      path: APP,
      icon: <DashboardIcon />,
    },

    {
      title: "Pricing Rules",
      path: PRICEING_RULES,
      icon: <PriceChangeIcon />,
    },

    {
      title: "Rules Settings",
      path: RULES_SETTINGS,
      icon: <SettingsIcon />,
    },

    {
      title: "Calculate Price",
      path: CALCULATE_PRICE,
      icon: <CalculateIcon />,
    },

    {
      title: "Rebates",
      path: REBATES,
      icon: <DiscountIcon />,
    },

    // {
    //   title: "Loyalty",
    //   path: LOYALTY,
    //   icon: <CardMembershipIcon />,
    // },
  ]

  const renderDrawerContent = (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "15px 24px 15px 24px" }}
      >
        <img src="/images/Logo.png" alt="Logo" width={180} />
      </Box>

      <Divider />

      <Box>
        <List disablePadding sx={{ p: 1 }}>
          {navConfig.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                className={classes.listItemBtn}
                component={RouterLink}
                to={item.path}
                disableGutters
                sx={{
                  "&.active": {
                    color: "#3366ff",
                    bgcolor: "#e4f2fc",
                    fontSize: "14.5px",
                  },
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText disableTypography primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* Mobile View Sidebar */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {renderDrawerContent}
      </Drawer>

      {/* Desktop View Sidebar */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {renderDrawerContent}
      </Drawer>
    </Box>
  )
}

export default Nav

const useStyles = makeStyles(() => ({
  listItemBtn: {
    height: 48,
    fontSize: "14px",
    position: "relative",
    textTransform: "capitalize",
    color: "#545861",
    borderRadius: "5px",
  },

  listItemIcon: {
    width: 22,
    height: 22,
    color: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))
