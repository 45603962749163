import { convertObjectToQueryParams } from "../../utils/convertObjectToQueryParams"
import { idmsApi } from "../api"
import {
  API_GET_PRICE_RANGES_FOR_DD,
  API_ADD_PRICE_RANGE,
  API_UPDATE_PRICE_RANGE,
  API_DELETE_PRICE_RANGE,
  API_GET_PRICE_RANGE_BY_ID,
  API_ADD_PRICE_RANGE_LEVEL,
  API_UPDATE_PRICE_RANGE_LEVEL,
  API_DELETE_PRICE_RANGE_LEVEL,
  API_GET_PRICING_IMPORT_STATUS,
  API_UPLOAD_PRICE_RANGE_CSV,
} from "../ApiConstants"

const priceRangeApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getPriceRanges: builder.query<any, any>({
      query: ({ DmsType, DmsDealerId }) => ({
        url: `${API_GET_PRICE_RANGES_FOR_DD}?DmsType=${DmsType}&DmsDealerId=${DmsDealerId}`,
        method: "GET",
      }),
    }),

    addPriceRange: builder.mutation<any, any>({
      query: (body) => ({
        url: API_ADD_PRICE_RANGE,
        method: "POST",
        body,
      }),
    }),

    updatePriceRange: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${API_UPDATE_PRICE_RANGE}/${payload.priceRangeId}`,
        method: "PUT",
        body: payload.body,
      }),
    }),

    deletePriceRange: builder.mutation<any, any>({
      query: ({ priceRangeId }) => ({
        url: `${API_DELETE_PRICE_RANGE}/${priceRangeId}`,
        method: "DELETE",
      }),
    }),

    getPriceRangeById: builder.query<any, any>({
      query: ({ id }) => ({
        url: `${API_GET_PRICE_RANGE_BY_ID}/${id}`,
        method: "GET",
      }),
    }),

    addPriceRangeLevel: builder.mutation<any, any>({
      query: (body) => ({
        url: `${API_ADD_PRICE_RANGE_LEVEL}/${body.priceRangeId}`,
        method: "POST",
        body,
      }),
    }),

    updatePriceRangeLevel: builder.mutation<any, any>({
      query: ({ id, requestBody }) => ({
        url: `${API_UPDATE_PRICE_RANGE_LEVEL}/${id}`,
        method: "PUT",
        body: requestBody,
      }),
    }),

    deletePriceRangeLevel: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `${API_DELETE_PRICE_RANGE_LEVEL}/${id}`,
        method: "DELETE",
      }),
    }),

    getPricingImportStatus: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_PRICING_IMPORT_STATUS}?${convertObjectToQueryParams(
          request,
        )}`,
        method: "GET",
      }),
    }),

    uploadPriceRangeCsv: builder.mutation<any, any>({
      query: (body) => ({
        url: API_UPLOAD_PRICE_RANGE_CSV,
        method: "POST",
        body,
      }),
    }),
  }),
})

export const {
  useGetPriceRangesQuery,
  useAddPriceRangeMutation,
  useUpdatePriceRangeMutation,
  useDeletePriceRangeMutation,
  useLazyGetPriceRangeByIdQuery,
  useAddPriceRangeLevelMutation,
  useUpdatePriceRangeLevelMutation,
  useDeletePriceRangeLevelMutation,
  useGetPricingImportStatusQuery,
  useUploadPriceRangeCsvMutation,
} = priceRangeApi
