import { FC, useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Button, LinearProgress } from "@mui/material"
import CancelIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import {
  useAddQuantityDiscountLevelMutation,
  useAddQuantityDiscountMutation,
  useDeleteQuantityDiscountLevelMutation,
  useDeleteQuantityDiscountMutation,
  useGetQuantityDiscountsQuery,
  useLazyGetQuantityDiscountsByIdQuery,
  useUpdateQuantityDiscountLevelMutation,
  useUpdateQuantityDiscountMutation,
  useUploadQtyDiscountCsvMutation,
} from "../../../services/API/quantityDiscountAPI"
import AddDialog from "./AddDialogue"
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import {
  getApiErrorMessage,
  getApplicationException,
} from "../../../utils/getApiErrorMessage"
import { findQuantityDiscountById } from "../../../helpers/helpers"
import FileUpload from "../../../components/common/FileUpload"
import { useGetPricingImportStatusQuery } from "../../../services/API/priceRangeAPI"

interface EditToolbarProps {
  quantityDiscountid: string
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void
  flag: boolean
  setFlag: (flag: boolean) => void
}

function EditToolbar(props: EditToolbarProps) {
  const { quantityDiscountid, setRows, setRowModesModel, flag, setFlag } = props

  const handleClick = async () => {
    try {
      const newRecord = {
        id: "",
        quantity: "",
        adjustAmountType: "",
        adjustAmount: "",
        discountType: "",
        isNew: true,
      }

      // Update local state with the new record
      setRows((oldRows) => [...oldRows, newRecord])

      // Set row mode to Edit for the new record
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [newRecord.quantity]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "quantity",
        },
      }))
      setFlag(false)
      // Callback to notify the parent component about the new record
      // onAddRecord(newRecord)
    } catch (error) {
      // Handle any error that may occur during the addition of the record
      console.error("Error adding record:", error)
    }
  }

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button
        color="primary"
        startIcon={<AddIcon />}
        disabled={quantityDiscountid === "" || !flag}
        onClick={handleClick}
      >
        Add
      </Button>
    </GridToolbarContainer>
  )
}

const QuantityDiscount: FC = () => {
  /*
   * Global States
   * */

  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )
  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )

  /*
   * Local States
   * */
  const [flag, setFlag] = useState(true)
  const [rows, setRows] = useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [isEditMode, setIsEditMode] = useState(false)
  const [quantityDiscountName, setQuantityDiscountName] = useState({
    id: undefined,
    name: "",
  })
  const [isAddModalOpen, setAddModalOpen] = useState(false)
  const [quantityDiscount, setQuantityDiscount] = useState({
    dmsType,
    dmsDealerId,
    companyDealerId,
    id: "",
    name: "",
    quantityDiscountLevels: [],
  })

  /*
   * API.
   * */

  const { data: quantityDiscounts, refetch: refetchQuantityDiscounts } =
    useGetQuantityDiscountsQuery({
      DmsType: dmsType,
      DmsDealerId: dmsDealerId,
    })

  const [addQuantityDiscount, { isLoading: addPriceRangeIsLoading }] =
    useAddQuantityDiscountMutation()

  const [updateQuantityDiscount] = useUpdateQuantityDiscountMutation()

  const [deleteQuantityDiscount] = useDeleteQuantityDiscountMutation()

  const [
    getQuantityDiscountLevels,
    { data: quantityDiscountLevelsData, isFetching: QdLevelsIsFetching },
  ] = useLazyGetQuantityDiscountsByIdQuery()

  const [addQuantityDiscountLevel] = useAddQuantityDiscountLevelMutation()

  const [updateQuantityDiscountLevel] = useUpdateQuantityDiscountLevelMutation()

  const [deleteQuantityDiscountLevel] = useDeleteQuantityDiscountLevelMutation()

  const { data: pricingImportStatus = {} } = useGetPricingImportStatusQuery({
    companyDealerId,
  })

  /*
   * Effects
   */

  // useEffect(() => {
  //   const fetchQdLevelsOnMount = async () => {
  //     if (quantityDiscounts && quantityDiscounts.data && quantityDiscounts.data.length > 0) {
  //       // Sort the data based on the id field in descending order
  //       // const sortedData = [...quantityDiscounts.data].sort((a, b) => {
  //       //   return b.id.localeCompare(a.id); // Sorting as strings
  //       // });

  //       // Initially select first option from sorted QdDropdown
  //       // setQuantityDiscount((prevState) => ({
  //       //   ...prevState,
  //       //   id: quantityDiscounts?.data[0]?.id,
  //       // }))

  //       // Fetch QuantityDiscountLevels against selected option
  //       await getQuantityDiscountLevels({ id: quantityDiscounts?.data[0]?.id })
  //     }
  //   };

  //   fetchQdLevelsOnMount();
  // }, [quantityDiscounts]);

  useEffect(() => {
    if (quantityDiscountLevelsData) {
      setRows(quantityDiscountLevelsData?.quantityDiscountLevels)
    }
  }, [quantityDiscountLevelsData?.quantityDiscountLevels])

  /*
   * Handlers
   * */

  const handleQuantityDiscountChangeForClick = async (id: GridRowId) => {
    try {
      const idAsString: string = id.toString()

      // Set the id in quantityDiscount state
      setQuantityDiscount((prevState) => ({
        ...prevState,
        id: idAsString,
      }))

      const response = await getQuantityDiscountLevels({ id })

      if ("error" in response) throw response

      // Handle other logic as needed
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleRowDoubleClick = (params: { id: GridRowId }) => {
    // Set flag to false when double-clicking on a row
    setFlag(false)
  }

  const handleAddButtonClick = () => {
    setIsEditMode(false)
    setAddModalOpen(true)
  }

  const handleModalClose = () => {
    setAddModalOpen(false)

    // Make modal field empty
    setQuantityDiscountName((prevState) => ({
      ...prevState,
      id: undefined,
      name: "",
    }))
  }

  const addQuantityDiscountAsync = async () => {
    try {
      const res = await addQuantityDiscount({
        dmsType,
        dmsDealerId,
        companyDealerId,
        code: null,
        name: quantityDiscountName.name.trim(),
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Quantity Discount Successfully Created")

      // Close modal
      setAddModalOpen(false)

      // Make modal field empty
      setQuantityDiscountName((prevState) => ({
        ...prevState,
        id: undefined,
        name: "",
      }))

      // Refetch QuantityDiscount
      refetchQuantityDiscounts()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const updateQuantityDiscountAsync = async () => {
    try {
      const res = await updateQuantityDiscount({
        quantityDiscountId: quantityDiscountName.id,
        body: {
          dmsType,
          dmsDealerId,
          companyDealerId,
          name: quantityDiscountName.name.trim(),
        },
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Quantity Discount Successfully Updated")

      // Close modal
      setAddModalOpen(false)

      // Make modal field empty
      setQuantityDiscountName((prevState) => ({
        ...prevState,
        id: undefined,
        name: "",
      }))

      // Refetch QuantityDiscount
      refetchQuantityDiscounts()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const deleteQuantityDiscountAsync = async (id: GridRowId) => {
    try {
      const res = await deleteQuantityDiscount({
        quantityDiscountId: id,
      })

      console.log("Response: ", res)

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Quantity Discount Successfully Deleted")

      // Refetch QuantityDiscount
      refetchQuantityDiscounts()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApplicationException(err))
      }
    }
  }

  const handleSaveButtonClick = async () => {
    if (isEditMode) {
      await updateQuantityDiscountAsync()
    } else {
      await addQuantityDiscountAsync()
    }
  }

  //----------------------------------------------//
  //              Data Grid Handlers             //
  //--------------------------------------------//

  function customeEditToolbar(props: EditToolbarProps) {
    return (
      <>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ justifyContent: "start", flexGrow: 1 }}>
            {!pricingImportStatus?.qtyDiscImport && (
              <FileUpload
                useUploadMutation={useUploadQtyDiscountCsvMutation}
                refetchData={refetchQuantityDiscounts}
              />
            )}
          </Box>

          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddButtonClick}
          >
            Add
          </Button>
        </GridToolbarContainer>
      </>
    )
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    setFlag(false)
  }

  const handleEditClickRange = (id: GridRowId) => async () => {
    try {
      const QdObject = findQuantityDiscountById(quantityDiscounts.data, id)

      if (QdObject) {
        setQuantityDiscountName((prevState) => ({
          ...prevState,
          id: QdObject.id,
          name: QdObject.name,
        }))

        setIsEditMode(true)
        setAddModalOpen(true)
      } else {
        toast.error("No Quantity Discount is selected to Edit.")
      }
    } catch (err) {
      console.error("Error finding Quantity Discount for edit:", err)
      toast.error(
        "An error occurred while finding the Quantity Discount to edit.",
      )
    }
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    setFlag(true)
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleQdLevelDelete(id)
          },
        },
        {
          label: "No",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const handleDeleteQuantityDiscount = (id: GridRowId) => async () => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deleteQuantityDiscountAsync(id)
          },
        },

        {
          label: "No",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const handleQdLevelDelete = async (id: GridRowId) => {
    try {
      const res = await deleteQuantityDiscountLevel({
        quantityDiscountId: quantityDiscount.id,
        quantityDiscountLevelId: id,
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Record Successfully Deleted")

      // ReFetch QuantityDiscountLevels
      await getQuantityDiscountLevels({ id: quantityDiscount.id })
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
    setFlag(true)
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      if (newRow.id === "") {
        // AddQuantityDiscountLevel API Call
        const res = await addQuantityDiscountLevel({
          quantityDiscountId: quantityDiscount.id,
          quantityDiscountLevel: {
            quantity: newRow.quantity,
            adjustAmountType: newRow.adjustAmountType,
            adjustAmount: newRow.adjustAmount,
            discountType: newRow.discountType,
          },
        })

        // Handle the error
        if ("error" in res) throw res

        // Handle the success response
        toast.success("Discount Level Successfully Created")

        // ReFetch QuantityDiscountLevels
        await getQuantityDiscountLevels({ id: quantityDiscount.id })

        // Return the newly added object to grid
        return (res as any)?.data
      } else {
        // UpdateQuantityDiscountLevel API Call
        const res = await updateQuantityDiscountLevel({
          quantityDiscountId: quantityDiscount.id,
          quantityDiscountLevelId: newRow.id,
          quantityDiscountLevel: {
            quantity: newRow.quantity,
            adjustAmountType: newRow.adjustAmountType,
            adjustAmount: newRow.adjustAmount,
            discountType: newRow.discountType,
          },
        })

        // Handle the error
        if ("error" in res) throw res

        // Handle the success response
        toast.success("Discount Level Successfully Updated")

        // ReFetch QuantityDiscountLevels
        await getQuantityDiscountLevels({ id: quantityDiscount.id })

        // Return the updated object to grid
        return (res as any)?.data
      }
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  //* Columns for Quantity Discount Grid
  const newGridColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
      sortable: false,
    },

    {
      field: "actions",
      type: "actions",
      // headerName: "",
      minWidth: 100,
      flex: 0.1,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,

            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClickRange(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteQuantityDiscount(id)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  //* Columns for Quantity Discount Levels Grid
  const columns: GridColDef[] = [
    // { field: "id", headerName: "Id", minWidth: 100, flex: 0.1 },

    {
      field: "quantity",
      headerName: "Purchase Qty >=",
      type: "number",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      sortable: false,
    },

    {
      field: "adjustAmountType",
      headerName: "Amount Type",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: [
        { value: "Increase", label: "Increase" },
        { value: "Decrease", label: "Decrease" },
      ],
    },

    {
      field: "adjustAmount",
      headerName: "Adjustment",
      type: "number",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      sortable: false,
    },

    {
      field: "discountType",
      headerName: "Discount Type",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: [
        { value: "Percentage", label: "Percentage" },
        { value: "FixedDiscount", label: "Fixed Discount" },
        { value: "FlatAmount", label: "Flat Amount" },
      ],
    },

    {
      field: "actions",
      type: "actions",
      // headerName: "",
      minWidth: 100,
      flex: 0.1,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={!flag}
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={!flag}
          />,
        ]
      },
    },
  ]

  const newGridData =
    (quantityDiscounts?.data ?? []).map((item: any) => ({
      id: item.id,
      name: item.name,
    })) ?? []

  return (
    <>
      {QdLevelsIsFetching && <LinearProgress sx={{ mb: 1 }} />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: 450,
            width: "35%",
            mb: 2,
            marginRight: "10px",
          }}
        >
          <DataGrid
            rows={newGridData}
            columns={newGridColumns}
            density="standard"
            disableColumnMenu
            disableColumnFilter
            onRowClick={(row) => handleQuantityDiscountChangeForClick(row.id)}
            hideFooterPagination={true}
            slots={{ toolbar: customeEditToolbar }}
          />
        </Box>

        <Box
          sx={{
            height: 450,
            width: "65%",
            mb: 2,
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows || []}
            columns={columns}
            editMode="row"
            onRowDoubleClick={handleRowDoubleClick}
            density="standard"
            disableColumnMenu
            disableColumnFilter
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: EditToolbar }}
            slotProps={{
              toolbar: {
                quantityDiscountid: quantityDiscount.id,
                setRows,
                setRowModesModel,
                flag,
                setFlag,
              },
            }}
          />
        </Box>
      </div>

      {/****** Add Dialog ******/}
      <AddDialog
        isEditMode={isEditMode}
        isOpen={isAddModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveButtonClick}
        setQuantityDiscountName={setQuantityDiscountName}
        quantityDiscountName={quantityDiscountName}
        isLoading={addPriceRangeIsLoading}
      />
    </>
  )
}

export default QuantityDiscount
