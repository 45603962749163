export function getApiErrorMessage(payload: any, successMessage?: string) {
  let message = ""
  if ((payload as any)?.error?.data) {
    var errors =
      (payload as any)?.error?.data?.Errors ||
      (payload as any)?.error?.data?.errors
    for (var key in errors)
      if (errors?.hasOwnProperty(key)) message += errors[key] + "\n"
  } else message = successMessage || message
  return message
}

export function getApiErrorMessageV2(payload: any, successMessage?: string) {
  let message = ""
  if ((payload as any)?.data) {
    var errors =
      (payload as any)?.data?.Errors || (payload as any)?.data?.errors
    for (var key in errors)
      if (errors?.hasOwnProperty(key)) message += errors[key] + "\n"
  } else message = successMessage || message
  return message
}

export const getApplicationException = (err: any): string => {
  // Check if the error object has the expected structure
  if (err?.error?.data?.Extensions?.exception?.Message) {
    // Return the specific error message
    return err.error.data.Extensions.exception.Message
  }

  // Fallback: return a generic error message if the specific message is not found
  return "An unexpected error occurred."
}
