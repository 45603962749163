import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { renderCellValue } from "./types/renderCellValue"
import { Checkbox, FormControlLabel } from "@mui/material"

export function getPricingRuleColumns(
  setActions?: (params: GridRowParams<any>) => JSX.Element[],
  renderCellValues?: renderCellValue[],
): GridColDef[] {
  let pricingRuleColumns = [
    {
      field: "ruleId",
      headerName: "Id",
      minWidth: 100,
      flex: 0.1,
      filterable: false,
    },
    {
      field: "ruleName",
      headerName: "Rule Name",
      minWidth: 100,
      flex: 0.15,
      filterable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 100,
      flex: 0.15,
      filterable: false,
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 100,
      flex: 0.1,
      filterable: false,

      renderCell: (params) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={params.row.active}
                onChange={(event) => {
                  const newValue = event.target.checked
                }}
              />
            }
            label=""
          />
        )
      },
    },
    {
      field: "archive",
      headerName: "Archive",
      minWidth: 100,
      flex: 0.1,
      filterable: false,

      renderCell: (params) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={params.row.archive}
                onChange={(event) => {
                  const newValue = event.target.checked
                }}
              />
            }
            label=""
          />
        )
      },
    },
    {
      field: "discounts",
      headerName: "Discounts",
      sortable: false,
      minWidth: 100,
      flex: 0.1,
      filterable: false,
    },
    {
      field: "isPermanent",
      headerName: "Duration",
      minWidth: 100,
      flex: 0.1,
      filterable: false,

      renderCell: (params) => {
        return <div>{params.row.isPermanent ? "Permanent" : "Limited"}</div>
      },
    },
    {
      field: "startDateTime",
      headerName: "Start",
      minWidth: 100,
      flex: 0.1,
      filterable: false,
    },
    {
      field: "endDateTime",
      headerName: "End",
      minWidth: 100,
      flex: 0.1,
      filterable: false,
    },
  ] as GridColDef[]

  if (renderCellValues)
    renderCellValues.forEach((renderCellValue) => {
      let pricingRuleColumn = pricingRuleColumns.find(
        (pricingRuleColumn) =>
          pricingRuleColumn.field === renderCellValue.field,
      )
      if (pricingRuleColumn)
        pricingRuleColumn.renderCell = renderCellValue.cellRenderer
    })

  if (setActions)
    pricingRuleColumns.push({
      field: "actions",
      headerName: "Action",
      flex: 0.1,
      minWidth: 10,
      type: "actions",

      getActions: (params: GridRowParams<any>) => setActions(params),
    })

  return pricingRuleColumns
}
