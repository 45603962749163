export enum FilterOperator {
  Contains = "Contains",
  Equals = "Equals",
  NotEquals = "Not Equals",
  StartsWith = "Starts With",
  EndsWith = "Ends With",
  LessThan = "<",
  GreaterThan = ">",
  LessThanOrEqual = "<=",
  GreaterThanOrEqual = ">=",
  IsEmpty = "Is Empty",
  IsNotEmpty = "Is Not Empty",
}

export const FilterColumnObj = {
  string: {
    UserName: "userName",
    ByName: "byName",
    Name: "name",
    ProductName: "productName",
  },
  number: {
    DealerId: "dealerId",
    DmsDealerId: "dmsDealerId",
  },
}

export enum UserFilterColumn {
  UserName = "userName",
  DealerId = "dealerId",
  DmsDealerId = "dmsDealerId",
}

export enum RoleFilterColumn {
  Name = "name",
  DmsDealerId = "dmsDealerId",
}

export enum ProductFilterColumn {
  ProductName = "productName",
}

export const pricingMethod = [
  { title: "MSRP", enum: "MSRP" },
  { title: "Target Margin", enum: "TargetMargin" },
  { title: "Markup", enum: "Markup" },
]

export const pricingColumn = [{ title: "Cost", enum: "Cost" }]

export const roundings = [
  { title: "None", enum: "None" },
  { title: "Rule of 9", enum: "RuleOf9" },
  { title: "Rule of 95", enum: "RuleOf95" },
  { title: "Rule of 99", enum: "RuleOf99" },
  { title: "Rule of 49/99", enum: "RuleOf499" },
  { title: "Simple Pricing", enum: "SimplePricing" },
]

export const priceRuleRoundings = [
  { title: "None", enum: "None" },
  { title: "Rule of 9", enum: "RuleOf9" },
  { title: "Rule of 95", enum: "RuleOf95" },
  { title: "Rule of 99", enum: "RuleOf99" },
  { title: "Rule of 49/99", enum: "RuleOf499" },
  { title: "Simple Pricing", enum: "SimplePricing" },
  { title: "Custom", enum: "Custom" },
]

// ---------- List Price Rule ---------- //
export const productTypes = [
  { name: "Part", enum: "Part" },
  { name: "Service", enum: "Service" },
  { name: "Whole Goods", enum: "WholeGood" },
]

export const locations = [
  { name: "Arlington", enum: "Arlington" },
  { name: "Cedar Rapids", enum: "CedarRapids" },
]

export const orderTypes = [
  { name: "Sales Order/Quote", enum: "SOQuote" },
  { name: "Repair/Estimate", enum: "ROEstimate" },
  { name: "Rental", enum: "Rental" },
  { name: "Warranty", enum: "Warranty" },
]

export const ruleType = [
  { name: "Percentage", enum: "Percentage" },
  { name: "Flat Rate Discount", enum: "FlatRateDiscount" },
  { name: "Fixed Selling Price", enum: "FixedSellingPrice" },
]

export const adjustPriceType = ["Increase", "Decrease"]

export const pricingColumns = [
  { name: "Dealer List", enum: "DealerList" },
  { name: "MSRP", enum: "MSRP" },
  { name: "Cost", enum: "Cost" },
]

export const disposition = [
  { name: "Any", enum: "Any" },
  { name: "New", enum: "New" },
  { name: "Used", enum: "Used" },
]

export const dateOptions = [
  { name: "Before", enum: "Before" },
  { name: "After", enum: "After" },
  { name: "Between", enum: "Between" },
]

export const customerType = ["Individual", "Organization"]

export const CustomerSaleHistoryDateOption = [
  { name: "None", enum: "None" },
  { name: "Before", enum: "Before" },
  { name: "After", enum: "After" },
  { name: "Between", enum: "Between" },
]

export const SaleCheckType = [
  { name: "Total Sales", enum: "TotalSaleCheck" },
  { name: "Service Sales", enum: "ServiceSaleCheck" },
  { name: "Product Sales", enum: "ProductSaleCheck" },
]

