import { idmsApi } from "../api"
import { API_LOGIN } from "../ApiConstants"

const accountsApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (request) => ({
        url: API_LOGIN,
        method: "POST",
        body: request,
      }),
    }),
  }),
})

export const { useLoginMutation } = accountsApi
