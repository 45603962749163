import { idmsApi } from "../api"
import {
  API_ADD_QUANTITY_DISCOUNT,
  API_ADD_QUANTITY_DISCOUNT_LEVEL,
  API_DELETE_QUANTITY_DISCOUNT,
  API_DELETE_QUANTITY_DISCOUNT_LEVEL,
  API_GET_QUANTITY_DISCOUNT_BY_ID,
  API_GET_QUANTITY_DISCOUNTS_FOR_DD,
  API_UPDATE_QUANTITY_DISCOUNT,
  API_UPDATE_QUANTITY_DISCOUNT_LEVEL,
  API_UPLOAD_QTY_DISC_CSV,
} from "../ApiConstants"

const quantityDiscountApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuantityDiscounts: builder.query<any, any>({
      query: ({ DmsType, DmsDealerId }) => ({
        url: `${API_GET_QUANTITY_DISCOUNTS_FOR_DD}?DmsType=${DmsType}&DmsDealerId=${DmsDealerId}`,
        method: "GET",
      }),
    }),

    addQuantityDiscount: builder.mutation<any, any>({
      query: (body) => ({
        url: API_ADD_QUANTITY_DISCOUNT,
        method: "POST",
        body,
      }),
    }),

    updateQuantityDiscount: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${API_UPDATE_QUANTITY_DISCOUNT}/${payload.quantityDiscountId}`,
        method: "PUT",
        body: payload.body,
      }),
    }),

    deleteQuantityDiscount: builder.mutation<any, any>({
      query: ({ quantityDiscountId }) => ({
        url: `${API_DELETE_QUANTITY_DISCOUNT}/${quantityDiscountId}`,
        method: "DELETE",
      }),
    }),

    getQuantityDiscountsById: builder.query<any, any>({
      query: ({ id }) => ({
        url: `${API_GET_QUANTITY_DISCOUNT_BY_ID}/${id}`,
        method: "GET",
      }),
    }),

    addQuantityDiscountLevel: builder.mutation<any, any>({
      query: (body) => ({
        url: `${API_ADD_QUANTITY_DISCOUNT_LEVEL}/${body.quantityDiscountId}`,
        method: "PUT",
        body,
      }),
    }),

    updateQuantityDiscountLevel: builder.mutation<any, any>({
      query: (body) => ({
        url: `${API_UPDATE_QUANTITY_DISCOUNT_LEVEL}/${body.quantityDiscountId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteQuantityDiscountLevel: builder.mutation<any, any>({
      query: ({ quantityDiscountId, quantityDiscountLevelId }) => ({
        url: `${API_DELETE_QUANTITY_DISCOUNT_LEVEL}?quantityDiscountId=${quantityDiscountId}&quantityDiscountLevelId=${quantityDiscountLevelId}`,
        method: "PUT",
      }),
    }),

    uploadQtyDiscountCsv: builder.mutation<any, any>({
      query: (body) => ({
        url: API_UPLOAD_QTY_DISC_CSV,
        method: "POST",
        body,
      }),
    }),
  }),
})

export const {
  useGetQuantityDiscountsQuery,
  useAddQuantityDiscountMutation,
  useUpdateQuantityDiscountMutation,
  useDeleteQuantityDiscountMutation,
  useLazyGetQuantityDiscountsByIdQuery,
  useAddQuantityDiscountLevelMutation,
  useUpdateQuantityDiscountLevelMutation,
  useDeleteQuantityDiscountLevelMutation,
  useUploadQtyDiscountCsvMutation,
} = quantityDiscountApi
