import { Autocomplete, TextField } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useLazyGetCustomerQuery } from "../../../services/API/pricingRuleAPI"

interface IProps {
  disabled?: boolean
  customerType: string[]
  customerCategory: string[]
  customerPriceLevel: string[]
  customers: ICustomers[]
  setCustomers: Dispatch<SetStateAction<ICustomers[]>>
}

const SearchableCustomerDD = ({
  disabled = false,
  customerType = [],
  customerCategory = [],
  customerPriceLevel = [],
  customers = [],
  setCustomers,
}: IProps) => {
  const [query, setQuery] = useState<string>("")
  const [options, setOptions] = useState<ICustomers[]>([])

  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )

  /*
   * API
   * */

  const [getCustomer, { data: customerData = [], isFetching }] =
    useLazyGetCustomerQuery()

  /*
   * Effects
   * */

  useEffect(() => {
    if (query.length >= 3) {
      getCustomer({
        companyDealerId,
        customerType,
        customerCategory,
        customerPriceLevel,
        Search: query,
      })
    }
  }, [query])

  // Update options whenever customer changes
  useEffect(() => {
    setOptions(customerData)
  }, [customerData])

  /*
   * Handler
   * */

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: ICustomers[],
  ) => {
    setCustomers(newValue)
  }

  return (
    <Autocomplete
      sx={{ width: "calc(100% - 8px)" }}
      multiple
      disabled={disabled}
      options={options}
      loading={isFetching}
      disableCloseOnSelect
      getOptionLabel={(option: ICustomers) => option?.organizationName}
      value={customers}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Customer(s)"
          variant="outlined"
          onChange={handleInputChange}
        />
      )}
    />
  )
}

export default SearchableCustomerDD
