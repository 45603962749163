import { GridColumnVisibilityModel, GridDensity } from "@mui/x-data-grid"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useAppSelector } from "../../app/hooks"

interface CommonState {
  density: GridDensity
  pagination: {
    page: number
    pageSize: number
  }
  columnVisisbilityModel: object
}

const initialState: CommonState = {
  density: "compact",
  pagination: {
    page: 0,
    pageSize: 15,
  },
  columnVisisbilityModel: {
    active: true,
    archive: true,
    description: true,
    discounts: true,
    endDate: true,
    isPermanent: true,
    ruleId: true,
    ruleName: true,
    startDate: true,
    actions: true,
  } as GridColumnVisibilityModel,
}

const gridSlice = createSlice({
  name: "grid",
  initialState: initialState,
  reducers: {
    setDensity: (state, action: PayloadAction<GridDensity>) => {
      state.density = action.payload
    },

    setGridPaginationModel: (state, action: PayloadAction<PaginationModel>) => {
      state.pagination.page = action.payload.page
      state.pagination.pageSize = action.payload.pageSize
    },

    setPricingRulesColumnVisibilityModel: (
      state,
      action: PayloadAction<GridColumnVisibilityModel>,
    ) => {
      state.columnVisisbilityModel = action.payload
    },
  },
})

export const {
  setDensity,
  setGridPaginationModel,
  setPricingRulesColumnVisibilityModel,
} = gridSlice.actions

export default gridSlice.reducer

export const useDensity = () =>
  useAppSelector((state: any) => state.grid.density)

export const usePaginationModel = () =>
  useAppSelector((state) => state.grid.pagination)

export const usePricingRulesColumnVisibilityModel = () =>
  useAppSelector((state) => state.grid.columnVisisbilityModel)
