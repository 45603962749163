import {
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "react-toastify"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useLazyGetNetPriceQuery } from "../../services/API/pricingRuleAPI"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { getApiErrorMessageV2 } from "../../utils/getApiErrorMessage"
import SearchableCustomerDDSS from "./components/SerchableCustomerDDSS"

const itemType = [
  { title: "Part", enum: "Part" },
  { title: "Whole Good", enum: "WholeGood" },
  { title: "Service", enum: "Service" },
]

const orderType = [
  { title: "SO/Quote", enum: "SOQuote" },
  { title: "RO/Estimate", enum: "ROEstimate" },
  { title: "Rental", enum: "Rental" },
  { title: "Warranty", enum: "Warranty" },
]

const customers = [
  { title: "C A S H   S A L E", id: "100001" },
  { title: "Employee, Dealer", id: "100910" },
  { title: "Customer, Online", id: "104405" },
  { title: "Krisnet Golf & Grass", id: "104490" },
  { title: "DUCKS UNLIMITED", id: "104330" },
  { title: "Duck, Donald", id: "106459" },
  { title: "North Pole Inc", id: "106461" },
  { title: "Mouse, Micky", id: "106468" },
]

const CalculatePrice = () => {
  const [calculatePriceForm, setCalculatePriceForm] = useState({
    itemId: "",
    manufacturerId: "",
    partNumber: "",
    quantity: 1,
    itemType: "",
    orderType: "",
    customerId: "",
    orderDate: null,
    onlineOrder: false,
    discountCode: "",
  })

  const [calculatedPrice, setCalculatedPrice] = useState({
    dealerListPrice: "",
    netPrice: "",
    rulesApplied: "",
    msrp: "",
    cost: "",
  })

  const [customer, setCustomer] = useState<ICustomers | null>(null)

  /*
   * Global States.
   * */

  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dealerId = useAppSelector((state: RootState) => state.auth.dealerId)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )

  /*
   * API.
   * */

  const [getNetPrice, { data: netPrice, isFetching: netPriceIsFetching }] =
    useLazyGetNetPriceQuery()

  /*
   * Handlers.
   * */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setCalculatePriceForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    setCalculatePriceForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "e") {
      event.preventDefault()
    }
  }

  const handleDateChange = (newValue: any) => {
    setCalculatePriceForm((prevState) => ({
      ...prevState,
      orderDate: newValue,
    }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setCalculatedPrice({
        dealerListPrice: "",
        netPrice: "",
        rulesApplied: "",
        msrp: "",
        cost: "",
      })

      if (customer !== null)
      {
        setCalculatePriceForm((prevState) => ({
          ...prevState,
          customerId: customer.customerId,
        }))
      }

      const res = await getNetPrice({
        dmsType,
        dmsDealerId,
        dealerId,
        ...calculatePriceForm,
      })

      if ("error" in res) throw res.error

      // Display the calculated price
      if ("data" in res) {
        setCalculatedPrice(res?.data)
        toast.success("Price Calculated Successfully")
      }
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessageV2(err))
      }
      // toast.error("Error in Getting Price")
      // console.error("Error:", error)
    }
  }

  return (
    <>
      <Helmet>
        <title> Calculate Price </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h6" gutterBottom>
            Calculate Price
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            {/* Left Grid */}
            <Grid item xs={4}>
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Item Id"
                name="itemId"
                value={calculatePriceForm.itemId}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Manufacturer"
                name="manufacturerId"
                value={calculatePriceForm.manufacturerId}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
              />

              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Part Number"
                name="partNumber"
                value={calculatePriceForm.partNumber}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
              />

              <TextField
                sx={{ mb: 1 }}
                fullWidth
                label="Quantity"
                name="quantity"
                type="number"
                variant="outlined"
                placeholder="Quantity"
                size="small"
                value={calculatePriceForm.quantity}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: -100000,
                }}
                onKeyDown={handleKeyDown}
              />

              <TextField
                sx={{ mb: 1 }}
                fullWidth
                required
                label="Item Type"
                placeholder="Item Type"
                name="itemType"
                size="small"
                select
                margin="dense"
                value={calculatePriceForm.itemType}
                onChange={handleChange}
              >
                {itemType?.map((item: any) => (
                  <MenuItem key={item.enum} value={item.enum}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ mb: 1 }}
                fullWidth
                required
                label="Order Type"
                placeholder="Order Type"
                name="orderType"
                size="small"
                select
                margin="dense"
                value={calculatePriceForm.orderType}
                onChange={handleChange}
              >
                {orderType?.map((item: any) => (
                  <MenuItem key={item.enum} value={item.enum}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>

              <Stack direction="row" alignItems="center">
                <Checkbox
                  name="onlineOrder"
                  inputProps={{ "aria-label": "controlled" }}
                  checked={calculatePriceForm.onlineOrder}
                  onChange={handleCheckboxChange}
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" sx={{ mr: 2, mb: 1 }}>
                  Online Order
                </Typography>
              </Stack>

              <SearchableCustomerDDSS
                        customer={customer}
                        setCustomer={setCustomer}
                      />

              <DatePicker
                sx={{ mb: 2, width: "100%" }}
                value={calculatePriceForm.orderDate}
                onChange={handleDateChange}
              />

              <TextField
                sx={{ mb: 4 }}
                fullWidth
                label="Discount Code"
                name="discountCode"
                value={calculatePriceForm.discountCode}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
              />

              {netPriceIsFetching ? (
                <Button
                  variant="contained"
                  type="submit"
                  disabled={netPriceIsFetching}
                  endIcon={<CircularProgress size="1.5rem" />}
                >
                  Calculate Price
                </Button>
              ) : (
                <Button variant="contained" type="submit">
                  Calculate Price
                </Button>
              )}
            </Grid>

            {/* Right Grid */}
            <Grid
              item
              xs={8}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <TextField
                disabled
                sx={{ mb: 2, width: "45%" }}
                label="Dealer List Price"
                name="dealerListPrice"
                value={calculatedPrice.dealerListPrice}
                size="small"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                disabled
                sx={{ mb: 2, width: "45%" }}
                label="Net Price"
                name="netPrice"
                value={calculatedPrice.netPrice}
                size="small"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                disabled
                sx={{ mb: 2, width: "45%" }}
                label="Rules Applied"
                name="rulesApplied"
                value={calculatedPrice.rulesApplied}
                size="small"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                disabled
                sx={{ mb: 2, width: "45%" }}
                label="MSRP"
                name="msrp"
                value={calculatedPrice.msrp}
                size="small"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                disabled
                sx={{ mb: 2, width: "45%" }}
                label="Cost"
                name="cost"
                value={calculatedPrice.cost}
                size="small"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  )
}

export default CalculatePrice
