import { Box, TextField, MenuItem, Button, IconButton } from "@mui/material";
import { GridFilterModel } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  UserFilterColumn,
  FilterOperator,
  FilterColumnObj,
  RoleFilterColumn,
  ProductFilterColumn,
} from "../../constants/enums";

interface CustomFilterPanelProps {
  columns: string[];
  applyFilters: (filters: GridFilterModel) => void;
  filterColumn: UserFilterColumn | RoleFilterColumn | ProductFilterColumn;
  setFilterColumn: (value: any) => void;
  filterOperator: FilterOperator;
  setFilterOperator: (value: FilterOperator) => void;
  filterValue: string;
  setFilterValue: (value: string) => void;
}

const CustomFilterPanel: React.FC<CustomFilterPanelProps> = (props) => {
  console.log("CustomFilterPanel", JSON.stringify(props, null, 2));
  const [column, setColumn] = useState(props.filterColumn);
  const [operator, setOperator] = useState(props.filterOperator);
  const [value, setValue] = useState(props.filterValue);
  type t = typeof props.filterColumn;

  const handleChangeOperator = (value: FilterOperator) => {
    setOperator(value);
    if (value === FilterOperator.IsEmpty || value === FilterOperator.IsNotEmpty)
      setValue("");
  };

  const handleClearFilters = () => {
    setColumn(props.columns.at(0) as UserFilterColumn);
    setOperator(FilterOperator.Equals);
    props.setFilterColumn(props.columns.at(0) as t);
    props.setFilterOperator(FilterOperator.Equals);
    props.setFilterValue("");
    props.applyFilters({
      items: [],
    });
  };

  const handleApplyFilter = () => {
    props.setFilterColumn(column);
    props.setFilterOperator(operator);
    props.setFilterValue(value);
    props.applyFilters({
      items: [
        {
          field: column,
          operator: operator,
          value: value,
        },
      ],
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        p: 1,
        gap: 1,
      }}
    >
      <IconButton
        onClick={handleClearFilters}
        title="Delete"
        size="small"
        sx={{
          alignSelf: "end",
        }}
        disabled={
          value === "" &&
          !(
            operator === FilterOperator.IsEmpty ||
            operator === FilterOperator.IsNotEmpty
          )
        }
      >
        <CloseIcon />
      </IconButton>
      <TextField
        select
        label="Column"
        value={column}
        onChange={(e) => {
          setColumn(e.target.value as UserFilterColumn);
          setOperator(FilterOperator.Equals);
        }}
        variant="standard"
      >
        {props.columns?.map((column, index) => (
          <MenuItem key={index} value={column}>
            {
              column
                .replace(/([A-Z])/g, " $1") // Add a space before every capital letter
                .trim() // Trim any leading or trailing spaces
                .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize the first letter
            }
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Operator"
        value={operator}
        onChange={(e) => handleChangeOperator(e.target.value as FilterOperator)}
        variant="standard"
      >
        {Object.values(FilterOperator)
          .filter((value) =>
            Object.values(FilterColumnObj.string).includes(column)
              ? value === FilterOperator.Contains ||
                value === FilterOperator.Equals ||
                value === FilterOperator.NotEquals ||
                value === FilterOperator.StartsWith ||
                value === FilterOperator.EndsWith ||
                value === FilterOperator.IsEmpty ||
                value === FilterOperator.IsNotEmpty
              : value === FilterOperator.Equals ||
                value === FilterOperator.NotEquals ||
                value === FilterOperator.LessThan ||
                value === FilterOperator.GreaterThan ||
                value === FilterOperator.LessThanOrEqual ||
                value === FilterOperator.GreaterThanOrEqual ||
                value === FilterOperator.IsEmpty ||
                value === FilterOperator.IsNotEmpty
          )
          .map((value) => (
            <MenuItem key={value} value={value}>
              {Object.values(FilterColumnObj.string).includes(column)
                ? value
                : value === FilterOperator.Equals
                ? "="
                : value === FilterOperator.NotEquals
                ? "!="
                : value}
            </MenuItem>
          ))}
      </TextField>

      <TextField
        label="Value"
        value={value}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleApplyFilter();
        }}
        onChange={(e) => setValue(e.target.value)}
        variant="standard"
        autoFocus
        disabled={
          operator === FilterOperator.IsEmpty ||
          operator === FilterOperator.IsNotEmpty
        }
        type={
          Object.values(FilterColumnObj.string).includes(column)
            ? "text"
            : "number"
        }
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyFilter}
        disabled={
          !(
            operator === FilterOperator.IsEmpty ||
            operator === FilterOperator.IsNotEmpty
          ) && value === ""
        }
      >
        Apply
      </Button>
    </Box>
  );
};

export default CustomFilterPanel;
