import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

interface AddDialogProps {
  isEditMode: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  quantityDiscountName: { id: undefined; name: string };
  setQuantityDiscountName: React.Dispatch<
    React.SetStateAction<{ id: undefined; name: string }>
  >;
  isLoading: boolean;
}

const AddDialog: React.FC<AddDialogProps> = ({
  isEditMode,
  isOpen,
  onClose,
  onSave,
  quantityDiscountName,
  setQuantityDiscountName,
  isLoading,
}) => {
  const isNameEmpty = quantityDiscountName.name.trim() === '';

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{isEditMode ? "Edit Name" : "Add Name"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={quantityDiscountName.name}
          onChange={(e) =>
            setQuantityDiscountName((prevState) => ({
              ...prevState,
              name: e.target.value,
            }))
          }
          fullWidth
          size="small"
          margin="dense"
          required 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave} color="primary" disabled={isLoading || isNameEmpty}>
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDialog;
