import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { ChangeEvent, FC, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "react-toastify"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { pricingColumn, pricingMethod, roundings } from "../../constants/enums"
import {
  useGetListPriceRuleQuery,
  useUpsertListPriceRuleMutation,
} from "../../services/API/pricingAPI"
import PriceRange from "./components/PriceRange"
import QuantityDiscount from "./components/QuantityDiscount"
import { useGetPriceRangesQuery } from "../../services/API/pricingRuleAPI"

const RulesSettings: FC = () => {
  /*
   * Global States
   * */

  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )

  /*
   * Local States
   * */

  const [partSection, setPartSection] = useState<ListPriceRule>({
    listPriceRuleId: 0,
    dmsType,
    dmsDealerId,
    name: "",
    description: "",
    pricingMethod: "",
    pricingColumn: "",
    maximizeProfit: true,
    percentage: 0,
    rounding: "",
    productType: "Part",
    priceRangeId: "",
  })

  const [wholegoodSection, setWholegoodSection] = useState<ListPriceRule>({
    listPriceRuleId: 0,
    dmsType,
    dmsDealerId,
    name: "",
    description: "",
    pricingMethod: "",
    pricingColumn: "",
    maximizeProfit: true,
    percentage: 0,
    rounding: "",
    productType: "WholeGood",
    priceRangeId: "",
  })

  /*
   * API.
   * */

  const {
    data: listPriceRule,
    isError: listPriceRuleIsError,
    refetch: refetchListPriceRule,
  } = useGetListPriceRuleQuery({
    DmsType: dmsType,
    DmsDealerId: dmsDealerId,
  })

  const [upsertListPriceRule, { isLoading: upsertListPriceRuleIsLoading }] =
    useUpsertListPriceRuleMutation()

  const { data: priceRanges = [] } = useGetPriceRangesQuery({
    DmsType: dmsType,
    DmsDealerId: dmsDealerId,
  })

  /*
   * Effects
   */

  useEffect(() => {
    if (listPriceRule && listPriceRule.length > 0) {
      let listPriceRuleP = listPriceRule.filter((r) =>
        r?.productType?.includes("Part"),
      )

      if (listPriceRuleP && listPriceRuleP.length > 0) {
        setPartSection((prevSettings) => ({
          ...prevSettings,
          listPriceRuleId: listPriceRuleP[0]?.listPriceRuleId,
          dmsType: listPriceRuleP[0]?.dmsType,
          dmsDealerId: listPriceRuleP[0]?.dmsDealerId,
          name: listPriceRuleP[0]?.name,
          description: listPriceRuleP[0]?.description,
          pricingMethod: listPriceRuleP[0]?.pricingMethod,
          pricingColumn: listPriceRuleP[0]?.pricingColumn,
          maximizeProfit: listPriceRuleP[0]?.maximizeProfit,
          percentage: listPriceRuleP[0]?.percentage,
          rounding: listPriceRuleP[0]?.rounding,
          priceRangeId: listPriceRuleP[0]?.priceRangeId,
        }))
      }

      let listPriceRuleW = listPriceRule.filter((r) =>
        r?.productType?.includes("WholeGood"),
      )

      if (listPriceRuleW && listPriceRuleW.length > 0) {
        setWholegoodSection((prevSettings) => ({
          ...prevSettings,
          listPriceRuleId: listPriceRuleW[0]?.listPriceRuleId,
          dmsType: listPriceRuleW[0]?.dmsType,
          dmsDealerId: listPriceRuleW[0]?.dmsDealerId,
          name: listPriceRuleW[0]?.name,
          description: listPriceRuleW[0]?.description,
          pricingMethod: listPriceRuleW[0]?.pricingMethod,
          pricingColumn: listPriceRuleW[0]?.pricingColumn,
          maximizeProfit: listPriceRuleW[0]?.maximizeProfit,
          percentage: listPriceRuleW[0]?.percentage,
          rounding: listPriceRuleW[0]?.rounding,
          priceRangeId: listPriceRuleW[0]?.priceRangeId,
        }))
      }
    }

    // Handle ListPriceRule error
    if (listPriceRuleIsError) {
      toast.error("List Price Rule Fecthing Failed")
    }
  }, [listPriceRule, listPriceRuleIsError])

  /*
   * Handlers
   * */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name == "pricingMethod" && value === "MSRP") {
      setPartSection((prevState) => ({
        ...prevState,
        pricingColumn: "",
        percentage: 0,
        [name]: value,
      }))
    } else {
      setPartSection((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  const handleWholegoodChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target

    if (name == "pricingMethod" && value === "MSRP") {
      setWholegoodSection((prevState) => ({
        ...prevState,
        pricingColumn: "",
        percentage: 0,
        [name]: value,
      }))
    } else {
      setWholegoodSection((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  const handlePercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    // Regular expression to match decimal or whole numbers
    const regex = /^[0-9]*\.?[0-9]*$/

    if (regex.test(value) || value === "") {
      setPartSection((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  const handleWholegoodPercentageChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target

    // Regular expression to match decimal or whole numbers
    const regex = /^[0-9]*\.?[0-9]*$/

    if (regex.test(value) || value === "") {
      setWholegoodSection((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  // const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = event.target
  //   setFormData((prevSettings) => ({
  //     ...prevSettings,
  //     [name]: checked,
  //   }))
  // }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const reqBody = {
      dealerListPriceRules: [partSection, wholegoodSection],
    }

    console.log("reqBody", reqBody)

    try {
      let res = await upsertListPriceRule(reqBody)
      if ("error" in res) {
        if (res.error) {
          throw res.error
        }
      }
      toast.success("Record Updated Successfully")
      refetchListPriceRule()
    } catch (error) {
      toast.error("Error upserting list price rule")
      console.error("Error upserting list price rule:", error)
    }
  }

  return (
    <>
      <Helmet>
        <title> Rules Settings </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h6" gutterBottom>
            Rules Settings
          </Typography>
        </Stack>

        {/*---------- Dealer List Price ----------*/}
        <Accordion
          defaultExpanded
          sx={{ boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.3)" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Dealer List Price</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSubmit}>
              <fieldset
                style={{
                  padding: "5px 20px 40px 20px",
                  fontSize: "small",
                  fontWeight: "bold",
                  border: "2px solid silver",
                  borderRadius: "10px",
                }}
              >
                <legend>&nbsp;Parts&nbsp;</legend>

                {/* ----- Parts Section ----- */}
                <Grid container rowSpacing={3} columnSpacing={2}>
                  {/* First Row */}
                  {/* <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Rule Name"
                      name="ruleName"
                      // value={form.dmsDealerId}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                      placeholder="Rule Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      // value={form.dmsDealerId}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                      placeholder="Rule Description"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid> */}

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      required
                      label="Pricing Method"
                      name="pricingMethod"
                      size="small"
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={partSection.pricingMethod}
                      onChange={handleChange}
                    >
                      {pricingMethod?.map((item: any) => (
                        <MenuItem key={item.enum} value={item.enum}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      required
                      label="Pricing Column"
                      placeholder="Pricing Column"
                      name="pricingColumn"
                      size="small"
                      select
                      margin="dense"
                      disabled={
                        partSection.pricingMethod === "MSRP" ? true : false
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={partSection.pricingColumn}
                      onChange={handleChange}
                    >
                      {pricingColumn?.map((item: any) => (
                        <MenuItem key={item.enum} value={item.enum}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      sx={{ my: "7.5px" }}
                      fullWidth
                      required
                      label="Percentage"
                      name="percentage"
                      value={partSection.percentage}
                      onChange={handlePercentageChange}
                      size="small"
                      type="text"
                      variant="outlined"
                      placeholder="Percentage"
                      disabled={
                        partSection.pricingMethod === "MSRP" ? true : false
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />

                    {/* <Stack direction="row" alignItems="center">
                      <Checkbox
                        name="maximizeProfit"
                        inputProps={{ "aria-label": "controlled" }}
                        checked={partSection.maximizeProfit}
                        onChange={handleCheckBox}
                      />
                      <Typography variant="body2" sx={{ mr: 2 }}>
                        Maximize Profit
                      </Typography>
                    </Stack> */}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Profit Maximizer"
                      placeholder="Profit Maximizer"
                      name="priceRangeId"
                      size="small"
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={partSection.priceRangeId}
                      onChange={handleChange}
                    >
                      {priceRanges?.map((item: any) => (
                        <MenuItem
                          key={item.priceRangeId}
                          value={item.priceRangeId}
                        >
                          {item.description}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Rounding"
                      name="rounding"
                      size="small"
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={partSection.rounding}
                      onChange={handleChange}
                    >
                      {roundings.map((item) => (
                        <MenuItem key={item.enum} value={item.enum}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </fieldset>

              {/* ----- Parts Section ----- */}
              <fieldset
                style={{
                  padding: "5px 20px 40px 20px",
                  marginTop: "16px",
                  fontSize: "small",
                  fontWeight: "bold",
                  border: "2px solid silver",
                  borderRadius: "10px",
                }}
              >
                <legend>&nbsp;Wholegoods&nbsp;</legend>

                <Grid container rowSpacing={3} columnSpacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      required
                      label="Pricing Method"
                      name="pricingMethod"
                      size="small"
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={wholegoodSection.pricingMethod}
                      onChange={handleWholegoodChange}
                    >
                      {pricingMethod?.map((item: any) => (
                        <MenuItem key={item.enum} value={item.enum}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      required
                      label="Pricing Column"
                      placeholder="Pricing Column"
                      name="pricingColumn"
                      size="small"
                      select
                      margin="dense"
                      disabled={
                        wholegoodSection.pricingMethod === "MSRP" ? true : false
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={wholegoodSection.pricingColumn}
                      onChange={handleWholegoodChange}
                    >
                      {pricingColumn?.map((item: any) => (
                        <MenuItem key={item.enum} value={item.enum}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      sx={{ my: "7.5px" }}
                      fullWidth
                      required
                      label="Percentage"
                      name="percentage"
                      value={wholegoodSection.percentage}
                      onChange={handleWholegoodPercentageChange}
                      size="small"
                      type="text"
                      variant="outlined"
                      placeholder="Percentage"
                      disabled={
                        wholegoodSection.pricingMethod === "MSRP" ? true : false
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Profit Maximizer"
                      placeholder="Profit Maximizer"
                      name="priceRangeId"
                      size="small"
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={wholegoodSection.priceRangeId}
                      onChange={handleWholegoodChange}
                    >
                      {priceRanges?.map((item: any) => (
                        <MenuItem
                          key={item.priceRangeId}
                          value={item.priceRangeId}
                        >
                          {item.description}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Rounding"
                      name="rounding"
                      size="small"
                      select
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={wholegoodSection.rounding}
                      onChange={handleWholegoodChange}
                    >
                      {roundings.map((item) => (
                        <MenuItem key={item.enum} value={item.enum}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </fieldset>

              <Grid item xs={12} mt={3} display="flex" justifyContent="end">
                {upsertListPriceRuleIsLoading ? (
                  <Button
                    sx={{ width: "15%" }}
                    endIcon={<CircularProgress size="1.5rem" />}
                    variant="contained"
                    type="submit"
                    disabled={upsertListPriceRuleIsLoading}
                  >
                    Saving
                  </Button>
                ) : (
                  <Button
                    sx={{ width: "15%" }}
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </form>
          </AccordionDetails>
        </Accordion>

        {/*---------- Range Rules ----------*/}
        <Accordion sx={{ boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.3)" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Profit Maximizer</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <PriceRange />
          </AccordionDetails>
        </Accordion>

        {/*---------- Quantity Discounts ----------*/}
        <Accordion sx={{ boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.3)" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Quantity Discounts</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <QuantityDiscount />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  )
}

export default RulesSettings
