import { FC, useEffect, useRef, useState } from "react"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { Button } from "@mui/material"
import { toast } from "react-toastify"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { getApiErrorMessageV2 } from "../../utils/getApiErrorMessage"

interface FileUploadProps {
  useUploadMutation: any
  refetchData?: any
}

const FileUpload: FC<FileUploadProps> = ({
  useUploadMutation,
  refetchData,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string | null>(null)

  /*
   * Global States
   * */
  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )
  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )

  /*
   * API
   * */

  const [uploadCSV, { data: responseData, isError, error, isSuccess }] =
    useUploadMutation()

  /*
   * Effects
   * */

  useEffect(() => {
    // Handle the success
    if (isSuccess) {
      toast.success("File Uploaded Successfully")
      resetFileInput()
      refetchData && refetchData()
    }

    // Handle the error
    if (isError) {
      toast.error(getApiErrorMessageV2(error))
      resetFileInput()
    }
  }, [isSuccess, isError])

  /*
   * Handlers
   * */

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      setFileName(event.target.files[0].name)
      const selectedFile = event.target.files[0]

      const formData = new FormData()
      formData.append("DmsType", dmsType)
      formData.append("DmsDealerId", dmsDealerId)
      formData.append("CompanyDealerId", companyDealerId)
      formData.append("file", selectedFile)

      try {
        const res = uploadCSV(formData)

        // Handle the error
        if ("error" in res) throw res

        // Handle the success
        //   toast.success("File Uploaded Successfully")
      } catch (error) {
        if ((error as any)?.error?.status == 401) {
          toast.error("User Unauthorized, Please Refresh the Page.")
        } else {
          toast.error(getApiErrorMessageV2(error))
        }
      }
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  // Reset the file input value to allow re-selecting the same file
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
      setFileName(null)
    }
  }

  return (
    <>
      <input
        type="file"
        accept=".txt, .csv"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      <Button
        color="primary"
        startIcon={<FileUploadIcon />}
        onClick={handleButtonClick}
      >
        Import
      </Button>

      {fileName && (
        <span style={{ marginLeft: "5px", color: "gray" }}>{fileName}</span>
      )}
    </>
  )
}

export default FileUpload
