import { Navigate, useRoutes } from "react-router-dom"
import { PrivateRoute } from "./utils/PrivateRoutes"
// layouts
import DashboardLayout from "./layouts/dashboard"
import SimpleLayout from "./layouts/simple"
// pages
import Login from "./pages/Login/Login"
import PricingDashboard from "./pages/PricingDashboard/PricingDashboard"
import Page404 from "./pages/Page404/Page404"
import { useCheckAuthToken } from "./hooks/useCheckAuthToken"
import PricingRuleList from "./pages/PricingRules/PricingRules"
import PricingRuleForm from "./pages/PricingRules/components/PricingRuleForm"
import {
  ADD_PRICING_RULE,
  APP,
  CALCULATE_PRICE,
  RULES_SETTINGS,
  PRICEING_RULES,
  UPDATE_PRICING_RULE,
  LOYALTY,
  REBATES,
} from "./constants/routeConstants"
import CalculatePrice from "./pages/CalculatePrice/CalculatePrice"
import RulesSettings from "./pages/RulesSettings/RulesSettings"
import Rebates from "./pages/Rebates/Rebates"
import Loyalty from "./pages/Loyalty/Loyalty"

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PRICEING_RULES} />, index: true },
        // { element: <Navigate to="/login" />, index: true },

        {
          path: APP,
          element: (
            <PrivateRoute
              element={<PricingDashboard />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: RULES_SETTINGS,
          element: (
            <PrivateRoute
              element={<RulesSettings />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: PRICEING_RULES,
          element: (
            <PrivateRoute
              element={<PricingRuleList />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: ADD_PRICING_RULE,
          element: (
            <PrivateRoute
              element={<PricingRuleForm />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: `${UPDATE_PRICING_RULE}/:ruleId`,
          element: (
            <PrivateRoute
              element={<PricingRuleForm />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: CALCULATE_PRICE,
          element: (
            <PrivateRoute
              element={<CalculatePrice />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: REBATES,
          element: (
            <PrivateRoute
              element={<Rebates />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },

        {
          path: LOYALTY,
          element: (
            <PrivateRoute
              element={<Loyalty />}
              isAuthenticated={useCheckAuthToken}
            />
          ),
        },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={PRICEING_RULES} />, index: true },
        // { element: <Navigate to="/login" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },

    {
      path: "login",
      element: <Login />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ])

  return routes
}
