import { useEffect, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Helmet } from "react-helmet-async"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import {
  arrayToCommaSeparatedString,
  commaSeparatedStringToArray,
  commaSeparatedStringToNumberArray,
  formatUserName,
  getInventoryPartIds,
  isOnlyWholegoodInArray,
} from "../../../helpers/helpers"
import {
  useAddPricingRuleMutation,
  useGetCustomerCategoryQuery,
  useGetCustomerPriceLevelQuery,
  useGetDealerLocationsQuery,
  useGetInventoryCategoryQuery,
  useGetManufacturerQuery,
  useGetPriceRangesQuery,
  useGetRuleByIdQuery,
  useUpdatePricingRuleMutation,
} from "../../../services/API/pricingRuleAPI"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import dayjs, { Dayjs } from "dayjs"
import {
  CustomerSaleHistoryDateOption,
  SaleCheckType,
  adjustPriceType,
  customerType,
  dateOptions,
  disposition,
  orderTypes,
  priceRuleRoundings,
  pricingColumns,
  productTypes,
  roundings,
  ruleType,
} from "../../../constants/enums"
import { PRICEING_RULES } from "../../../constants/routeConstants"
import { useGetQuantityDiscountsQuery } from "../../../services/API/quantityDiscountAPI"
import { getApiErrorMessage } from "../../../utils/getApiErrorMessage"
import { confirmAlert } from "react-confirm-alert"
import SearchableProductDD from "./SearchableProductDD"
import SearchableUnitDD from "./SearchableUnitDD"
import SearchableCustomerDD from "./SearchableCustomerDD"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const initialFormState = {
  ruleName: "",
  description: "",
  active: false,
  archive: false,
  adjustmentType: "",
  adjustmentLevel: "",
  stackable: false,
  duration: "",
  startDate: null,
  endDate: null,
  customerCategory: "",
  customerPriceLevel: "",
  manufacturer: "",
  inventoryCategory: "",
  productType: "",
  dealership: false,
  stores: null,
  orderType: "",
  customerType: "",
  ruleType: "",
  adjustPriceType: "",
  adjustColumn: "",
  adjustAmount: 0,
  quantityDiscountId: "",
  rounding: "",
  discountCode: "",
  location: "",
  online: false,
  product: null,
  inventoryWGId: "",
  disposition: "",
  priceRangeId: "",
  receivedItemDateOption: "",
  receivedItemStartDate: null,
  receivedItemEndDate: null,
  products: null,
  units: null,
  saleAmount: 0,
}

const initialCustomRounding = {
  roundTo: 0,
  subtract: 0,
}

const PricingRuleForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentRoute = location.pathname
  let { ruleId } = useParams()

  //* Checking the current route
  let isAddRuleRoute = false
  if (currentRoute.includes("add-pricing-rule")) isAddRuleRoute = true

  /*
   * Global States
   * */

  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )
  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )

  /*
   * Local States
   * */
  const [expanded, setExpanded] = useState<string | false>("panel1")
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [receivedItemStartDate, setReceivedItemStartDate] =
    useState<Dayjs | null>(null)
  const [receivedItemEndDate, setReceivedItemEndDate] = useState<Dayjs | null>(
    null,
  )
  const [customerCat, setCustomerCat] = useState<string[]>([])
  const [customerTypeState, setCustomerTypeState] = useState<string[]>([])
  const [customerPriceLvl, setCustomerPriceLvl] = useState<string[]>([])
  const [manufacturerList, setManufacturerList] = useState<string[]>([])
  const [inventoryCat, setInventoryCat] = useState<string[]>([])
  const [productType, setProductType] = useState<string[]>([])
  const [dealerLocation, setDealerLocation] = useState<number[]>([])
  const [orderType, setOrderType] = useState<string[]>([])
  const [selectedProducts, setSelectedProducts] = useState<PartsModels[]>([])
  const [selectedUnit, setSelectedUnit] = useState<UnitsData[]>([])
  const [inventoryPartIds, setInventoryPartIds] = useState<string>("")
  const [pricingRuleForm, setPricingRuleForm] = useState({
    ruleName: "",
    description: "",
    active: true,
    archive: false,
    adjustmentType: "Automatic",
    adjustmentLevel: "Item",
    stackable: false,
    duration: "Permanent",
    startDate: null,
    endDate: null,
    customerCategory: "",
    customerPriceLevel: "",
    manufacturer: "",
    inventoryCategory: "",
    productType: "",
    location: "",
    dealership: true,
    orderType: "",
    customerType: "",
    ruleType: "",
    adjustPriceType: "",
    adjustColumn: "",
    adjustAmount: 0,
    quantityDiscountId: "",
    rounding: "None",
    discountCode: "",
    online: false,
    disposition: "",
    priceRangeId: "",
    receivedItemDateOption: "",
    receivedItemStartDate: null,
    receivedItemEndDate: null,
    products: null,
    units: null,
    saleAmount: 0,
  })

  const [saleCheckStartDate, setSaleCheckStartDate] = useState<Dayjs | null>(
    null,
  )
  const [saleCheckEndDate, setSaleCheckEndDate] = useState<Dayjs | null>(null)

  const [saleVolumeInventoryCat, setSaleVolumeInventoryCat] = useState<
    string[]
  >([])

  const [saleVolumeManufacturerList, setSaleVolumeManufacturerList] = useState<
    string[]
  >([])

  const [saleVolumeSelectedProducts, setSaleVolumeSelectedProducts] = useState<
    PartsModels[]
  >([])

  const [customers, setCustomers] = useState<ICustomers[]>([])

  const [saleVolumeCheck, setSaleVolumeCheck] = useState({
    saleCheckType: "",
    saleCheckAmount: 0,
    saleCheckDateOption: "None",
    saleCheckStartDate: null,
    saleCheckEndDate: null,
    inventoryCategory: "",
    manufacturer: "",
    products: null,
  })

  const [customRounding, setCustomRounding] = useState({
    roundTo: 0,
    subtract: 0,
  })

  /*
   * API
   * */

  const { data: customerCategory = [] } = useGetCustomerCategoryQuery({
    companyDealerId,
  })

  const { data: manufacturer = [] } = useGetManufacturerQuery({
    companyDealerId,
  })

  const { data: inventoryCategory = [] } = useGetInventoryCategoryQuery({
    companyDealerId,
  })

  const {
    data: singlePricingRule,
    isFetching: pricingRuleIsFetching,
    isSuccess: pricingRuleIsSuccess,
  } = useGetRuleByIdQuery(ruleId, {
    skip: isAddRuleRoute,
    refetchOnMountOrArgChange: true,
  })

  const [addPricingRule, { isLoading: addPricingRuleIsLoading }] =
    useAddPricingRuleMutation()

  const [updatePricingRule, { isLoading: updatePricingRuleIsLoading }] =
    useUpdatePricingRuleMutation()

  const { data: quantityDiscounts } = useGetQuantityDiscountsQuery({
    DmsType: dmsType,
    DmsDealerId: dmsDealerId,
  })

  const { data: dealerLocations = [] } = useGetDealerLocationsQuery({
    companyDealerId,
  })

  const { data: priceRanges = [] } = useGetPriceRangesQuery({
    DmsType: dmsType,
    DmsDealerId: dmsDealerId,
  })

  const { data: customerPriceLevels = [] } = useGetCustomerPriceLevelQuery({
    companyDealerId,
  })

  /*
   * Effects
   * */

  // Reset location-dropdown if dealership is true
  useEffect(() => {
    if (pricingRuleForm.dealership) setDealerLocation([])
  }, [pricingRuleForm.dealership])

  useEffect(() => {
    if (productType) {
      if (!isOnlyWholegoodInArray(productType)) {
        resetWholegoodOptions()
      }
    }
  }, [productType])

  useEffect(() => {
    if (selectedProducts) {
      setInventoryPartIds(getInventoryPartIds(selectedProducts))
    }
  }, [selectedProducts])

  // Populate Form to Update Rule
  useEffect(() => {
    if (pricingRuleIsSuccess) {
      setPricingRuleForm((prevState) => ({
        ...prevState,
        ruleName: singlePricingRule?.ruleName || "",
        description: singlePricingRule?.description || "",
        active: singlePricingRule?.active,
        archive: singlePricingRule?.archive || false,
        adjustmentType: singlePricingRule?.adjustmentType || "",
        adjustmentLevel: singlePricingRule?.adjustmentLevel || "",
        stackable: singlePricingRule?.stackable || false,
        duration: singlePricingRule?.duration || "",
        dealership: singlePricingRule?.dealership || false,
        ruleType: singlePricingRule?.ruleType || "",
        adjustPriceType: singlePricingRule?.adjustPriceType || "",
        adjustColumn: singlePricingRule?.adjustColumn || "",
        adjustAmount: singlePricingRule?.adjustAmount || 0,
        quantityDiscountId: singlePricingRule?.quantityDiscountId || "",
        rounding: singlePricingRule?.rounding || "",
        discountCode: singlePricingRule?.discountCode || "",
        online: singlePricingRule?.online || false,
        disposition: singlePricingRule?.disposition || "",
        priceRangeId: singlePricingRule?.priceRangeId || "",
        receivedItemDateOption: singlePricingRule?.receivedItemDateOption || "",
        saleAmount: singlePricingRule?.saleAmount || 0,
      }))

      // Populate startDate & endDate
      setStartDate(
        singlePricingRule.startDate ? dayjs(singlePricingRule.startDate) : null,
      )
      setEndDate(
        singlePricingRule.endDate ? dayjs(singlePricingRule.endDate) : null,
      )

      setCustomerCat([
        ...commaSeparatedStringToArray(singlePricingRule?.customerCategory),
      ])

      setCustomerPriceLvl([
        ...commaSeparatedStringToArray(singlePricingRule?.customerPriceLevel),
      ])

      setManufacturerList([
        ...commaSeparatedStringToArray(singlePricingRule?.manufacturer),
      ])
      setInventoryCat([
        ...commaSeparatedStringToArray(singlePricingRule?.inventoryCategory),
      ])
      setProductType([
        ...commaSeparatedStringToArray(singlePricingRule?.productType),
      ])
      setDealerLocation([
        ...commaSeparatedStringToNumberArray(singlePricingRule?.location),
      ])
      setOrderType([
        ...commaSeparatedStringToArray(singlePricingRule?.orderType),
      ])
      setCustomerTypeState([
        ...commaSeparatedStringToArray(singlePricingRule?.customerType),
      ])
      setCustomers(singlePricingRule?.customers || [])

      // Populate receivedItem startDate & endDate
      setReceivedItemStartDate(
        singlePricingRule.receivedItemStartDate
          ? dayjs(singlePricingRule.receivedItemStartDate)
          : null,
      )

      setReceivedItemEndDate(
        singlePricingRule.receivedItemEndDate
          ? dayjs(singlePricingRule.receivedItemEndDate)
          : null,
      )

      setSelectedProducts(singlePricingRule?.products || [])
      setSelectedUnit(singlePricingRule?.units || [])

      // ********** Sales Volume Section ********** //

      setSaleVolumeCheck((prevState) => ({
        ...prevState,
        saleCheckType: singlePricingRule?.saleVolumeCheck?.saleCheckType || "",
        saleCheckAmount:
          singlePricingRule?.saleVolumeCheck?.saleCheckAmount || 0,
        saleCheckDateOption:
          singlePricingRule?.saleVolumeCheck?.saleCheckDateOption || "None",
      }))

      setSaleCheckStartDate(
        singlePricingRule?.saleVolumeCheck?.saleCheckStartDate
          ? dayjs(singlePricingRule?.saleVolumeCheck?.saleCheckStartDate)
          : null,
      )

      setSaleCheckEndDate(
        singlePricingRule?.saleVolumeCheck?.saleCheckEndDate
          ? dayjs(singlePricingRule?.saleVolumeCheck?.saleCheckEndDate)
          : null,
      )

      setSaleVolumeManufacturerList([
        ...commaSeparatedStringToArray(
          singlePricingRule?.saleVolumeCheck?.manufacturer,
        ),
      ])

      setSaleVolumeInventoryCat([
        ...commaSeparatedStringToArray(
          singlePricingRule?.saleVolumeCheck?.inventoryCategory,
        ),
      ])

      setSaleVolumeSelectedProducts(
        singlePricingRule?.saleVolumeCheck?.products || [],
      )

      setCustomRounding((prevState) => ({
        ...prevState,
        roundTo: singlePricingRule?.customRounding?.roundTo || 0,
        subtract: singlePricingRule?.customRounding?.subtract || 0,
      }))
    }
  }, [pricingRuleIsSuccess, singlePricingRule])

  /*
   * Handler
   * */

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target

    if (type === "checkbox") {
      setPricingRuleForm((prevSettings) => ({
        ...prevSettings,
        [name]: checked,
      }))
    } else {
      setPricingRuleForm((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

    // Setting startDate & endDate to null if duration is Permanent
    if (name === "duration" && value === "Permanent") {
      setStartDate(null)
      setEndDate(null)
    }

    // Setting startDate & endDate to null if duration is Permanent
    if (name == "ruleType" && value == ruleType[2].enum) {
      setPricingRuleForm((prevState) => ({
        ...prevState,
        adjustPriceType: "",
        adjustColumn: "",
      }))
    }
  }

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    // Validate and allow only numbers with up to 2 decimal places
    const regex = /^\d+(\.\d{0,2})?$/
    if (value === "" || regex.test(value)) {
      setPricingRuleForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const salesVolumeHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target

    // Reset values
    if (
      name === "saleCheckType" &&
      (value === "TotalSaleCheck" || value === "ServiceSaleCheck")
    ) {
      setSaleVolumeManufacturerList([])
      setSaleVolumeInventoryCat([])
      setSaleVolumeSelectedProducts([])
    }

    // Reset values
    if (name === "saleCheckDateOption" && value === "None") {
      setSaleCheckStartDate(null)
      setSaleCheckEndDate(null)
    }

    // Reset values
    if (
      name === "saleCheckDateOption" &&
      (value === "Before" || value === "After")
    ) {
      setSaleCheckEndDate(null)
    }

    setSaleVolumeCheck((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }))
  }

  const handleSaleCheckDate = (newValue: any, key: string) => {
    if (key === "startDate") {
      setSaleCheckStartDate(newValue)
    } else if (key === "endDate") {
      setSaleCheckEndDate(newValue)
    }
  }

  const handleSalesVolumeInventoryCatChange = (
    event: SelectChangeEvent<string[]>,
  ) => {
    const {
      target: { value },
    } = event

    setSaleVolumeInventoryCat(
      typeof value === "string" ? value.split(",") : value,
    )
  }

  const handleSalesVolumeManufacturerChange = (
    event: SelectChangeEvent<string[]>,
  ) => {
    const {
      target: { value },
    } = event

    setSaleVolumeManufacturerList(
      typeof value === "string" ? value.split(",") : value,
    )
  }

  const handleApplicationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target

    setPricingRuleForm((prevSettings) => ({
      ...prevSettings,
      adjustColumn: "",
      [name]: value,
    }))

    if (value === "Order") {
      setPricingRuleForm((prevSettings) => ({
        ...prevSettings,
        quantityDiscountId: "",
        rounding: "",
        stackable: false,
      }))

      resetProductOptions()
    }
  }

  const wholegoodOptionsChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target
    if (
      name === "receivedItemDateOption" &&
      (value === "Before" || value === "After")
    ) {
      setReceivedItemEndDate(null)
    }

    setPricingRuleForm((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }))
  }

  const handleDateChange = (newValue: Dayjs | null, key: string) => {
    if (key === "startDate" && newValue) {
      setStartDate(newValue)
    } else if (key === "endDate" && newValue) {
      setEndDate(newValue)
    }
  }

  const handleReceiveDate = (newValue: any, key: string) => {
    if (key === "startDate") {
      setReceivedItemStartDate(newValue)
    } else if (key === "endDate") {
      setReceivedItemEndDate(newValue)
    }
  }

  const handleCustomerTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    setCustomerTypeState(typeof value === "string" ? value.split(",") : value)
  }

  const handleCustomerCategoryChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    setCustomerCat(typeof value === "string" ? value.split(",") : value)
    // setCustomerCat(value as string[])
  }

  const handleManufacturerChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    setManufacturerList(typeof value === "string" ? value.split(",") : value)
  }

  const handleInventoryCategoryChange = (
    event: SelectChangeEvent<string[]>,
  ) => {
    const {
      target: { value },
    } = event

    setInventoryCat(typeof value === "string" ? value.split(",") : value)
  }

  const handleProductTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    setProductType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    )
  }

  const handleDealerLocationChange = (event: SelectChangeEvent<number[]>) => {
    setDealerLocation(event.target.value as number[])
  }

  const handleOrderTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    setOrderType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    )
  }

  const handleCustomerPriceLevelChange = (
    event: SelectChangeEvent<string[]>,
  ) => {
    const {
      target: { value },
    } = event

    setCustomerPriceLvl(typeof value === "string" ? value.split(",") : value)
  }

  const handleCustomRoundingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target

    // Validate and allow only numbers with up to 2 decimal places
    const regex = /^\d+(\.\d{0,2})?$/
    if (value === "" || regex.test(value)) {
      setCustomRounding((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }))
    }
  }

  const handleKeyDown = (event: any) => {
    const forbiddenKeys = ["$", "%", "-"]

    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const handleConfirmAlert = () => {
    confirmAlert({
      title: "Confirmation",
      message: "Do you want to add another rule?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            resetForm()
            return
          },
        },
        {
          label: "No",
          onClick: () => {
            resetForm()
            navigate(PRICEING_RULES)
          },
        },
      ],
    })
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const reqBody = {
      ...pricingRuleForm,
      startDate: startDate ? startDate.format("MM/DD/YYYY") : null,
      endDate: endDate ? endDate.format("MM/DD/YYYY") : null,
      customerCategory: arrayToCommaSeparatedString(customerCat),
      customerPriceLevel: arrayToCommaSeparatedString(customerPriceLvl),
      manufacturer: arrayToCommaSeparatedString(manufacturerList),
      inventoryCategory: arrayToCommaSeparatedString(inventoryCat),
      productType: arrayToCommaSeparatedString(productType),
      location: arrayToCommaSeparatedString(dealerLocation),
      orderType: arrayToCommaSeparatedString(orderType),
      customerType: arrayToCommaSeparatedString(customerTypeState),
      customers,
      receivedItemStartDate: receivedItemStartDate
        ? receivedItemStartDate.format()
        : null,
      receivedItemEndDate: receivedItemEndDate
        ? receivedItemEndDate.format()
        : null,
      products: selectedProducts,
      units: selectedUnit,
      saleVolumeCheck: {
        saleCheckType: saleVolumeCheck.saleCheckType,
        saleCheckAmount: saleVolumeCheck.saleCheckAmount,
        saleCheckDateOption: saleVolumeCheck.saleCheckDateOption,
        saleCheckStartDate: saleCheckStartDate
          ? saleCheckStartDate.format()
          : null,
        saleCheckEndDate: saleCheckEndDate ? saleCheckEndDate.format() : null,
        inventoryCategory: arrayToCommaSeparatedString(saleVolumeInventoryCat),
        manufacturer: arrayToCommaSeparatedString(saleVolumeManufacturerList),
        products: saleVolumeSelectedProducts,
      },
      customRounding: {
        roundTo: customRounding.roundTo,
        subtract: customRounding.subtract,
      },
    }

    console.log("Add Rule ReqBody:", reqBody)

    //*----- Add Rule API Call -----//
    if (isAddRuleRoute) {
      try {
        const addRuleRes = await addPricingRule({
          companyDealerId,
          dmsType,
          dmsDealerId,
          ...reqBody,
        })

        // Handle the error
        if ("error" in addRuleRes) throw addRuleRes

        // Handle the success response
        toast.success("Rule Added Successfully")
        handleConfirmAlert()
      } catch (err) {
        if ((err as any)?.error?.status == 401) {
          toast.error("User Unauthorized, Please Refresh the Page.")
        } else {
          toast.error(getApiErrorMessage(err))
        }
      }
    }
    //*----- Update Rule API Call -----//
    else {
      try {
        const updateRuleRes = await updatePricingRule({
          ruleId,
          requestBody: {
            companyDealerId,
            dmsType,
            dmsDealerId,
            ...reqBody,
          },
        })

        // Handle the error
        if ("error" in updateRuleRes) throw updateRuleRes

        // Handle the success response
        toast.success("Rule Updated Successfully")
        navigate(PRICEING_RULES) // Navigate Back to Rules Page
      } catch (err) {
        if ((err as any)?.error?.status == 401) {
          toast.error("User Unauthorized, Please Refresh the Page.")
        } else {
          toast.error(getApiErrorMessage(err))
        }
      }
    }
  }

  const resetForm = () => {
    setCustomerCat([])
    setCustomerPriceLvl([])
    setManufacturerList([])
    setInventoryCat([])
    setProductType([])
    setDealerLocation([])
    setOrderType([])
    setCustomerTypeState([])
    setCustomers([])
    setReceivedItemStartDate(null)
    setReceivedItemEndDate(null)
    setSelectedProducts([])
    setSelectedUnit([])
    setInventoryPartIds("")
    setPricingRuleForm({ ...initialFormState })
    setCustomRounding({ ...initialCustomRounding })
  }

  const resetWholegoodOptions = () => {
    setPricingRuleForm((prevSettings) => ({
      ...prevSettings,
      disposition: "",
      receivedItemDateOption: "",
    }))

    setSelectedUnit([])
    setReceivedItemStartDate(null)
    setReceivedItemEndDate(null)
  }

  const resetProductOptions = () => {
    setProductType([])
    setInventoryCat([])
    setManufacturerList([])
    setSelectedProducts([])
  }

  return (
    <>
      <Helmet>
        <title>
          {isAddRuleRoute ? "Add Pricing Rule" : "Update Pricing Rule"}
        </title>
      </Helmet>

      {pricingRuleIsFetching && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <LinearProgress />
        </Box>
      )}

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h6" gutterBottom>
            {isAddRuleRoute ? "Add Pricing Rule" : "Update Pricing Rule"}
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit}>
          <fieldset
            style={{
              position: "sticky",
              top: 50,
              backgroundColor: "#fff",
              zIndex: 2,
              padding: "40px 20px",
              marginBottom: "30px",
              fontSize: "medium",
              border: "1px solid silver",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              // justifyContent="space-between"
            >
              <TextField
                sx={{ mr: 2, minWidth: "20%" }}
                required
                label="Rule Name"
                name="ruleName"
                value={pricingRuleForm.ruleName}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
                placeholder="Rule Name"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                sx={{ mr: 2, minWidth: "40%" }}
                label="Description"
                name="description"
                value={pricingRuleForm.description}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
                placeholder="Rule Description"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Box>
                <FormControlLabel
                  value="end"
                  name="active"
                  control={
                    <Checkbox
                      disabled={singlePricingRule?.archive}
                      checked={pricingRuleForm.active}
                      onChange={handleChange}
                    />
                  }
                  label="Active?"
                  labelPlacement="end"
                />
              </Box>

              <Box flexGrow={1}>
                <FormControlLabel
                  value="end"
                  name="archive"
                  control={
                    <Checkbox checked={pricingRuleForm.archive} disabled />
                  }
                  label="Archive?"
                  labelPlacement="end"
                />
              </Box>

              {addPricingRuleIsLoading || updatePricingRuleIsLoading ? (
                <Button
                  sx={{ textTransform: "none", width: "80px" }}
                  variant="contained"
                  type="submit"
                  disabled={
                    addPricingRuleIsLoading || updatePricingRuleIsLoading
                  }
                  endIcon={<CircularProgress size="1.5rem" />}
                >
                  Saving
                </Button>
              ) : (
                <Button
                  sx={{ textTransform: "none", width: "80px" }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </Stack>
          </fieldset>

          {/*---------- Adjustment Rules Accordion ----------*/}
          <Accordion
            defaultExpanded
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography sx={{ width: "20%", flexShrink: 0 }}>
                Adjustment Rules
              </Typography>

              {/* <Typography sx={{ color: "text.secondary" }}>
                I am an accordion
              </Typography> */}

              <Stack direction="row" spacing={1}>
                <Chip
                  label={pricingRuleForm.adjustmentType}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label={pricingRuleForm.duration}
                  color="primary"
                  variant="outlined"
                  size="small"
                />

                <Chip
                  label={pricingRuleForm.adjustmentLevel}
                  color="primary"
                  variant="outlined"
                  size="small"
                />

                {pricingRuleForm.stackable && (
                  <Chip
                    label={"Stackable"}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
              <Grid container rowSpacing={1} columnSpacing={2}>
                {/* <Grid item xs={12}>
                  <fieldset
                    style={{
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                    }}
                  >
                    <legend>&nbsp;Adjustment Rules Summary&nbsp;</legend>

                    <Typography sx={{ p: 2 }} variant="h6">
                      Coming Soon
                    </Typography>
                  </fieldset>
                </Grid> */}

                {/*----- Enablement Block -----*/}
                <Grid item xs={4}>
                  <fieldset
                    style={{
                      padding: "5px 20px 80px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Enablement&nbsp;</legend>
                    <>
                      <FormControl>
                        <RadioGroup
                          name="adjustmentType"
                          value={pricingRuleForm.adjustmentType}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            required
                            value="Automatic"
                            control={<Radio />}
                            label="Automatic"
                          />

                          <FormControlLabel
                            value="DiscountCode"
                            control={<Radio />}
                            label="Discount Code"
                          />
                        </RadioGroup>
                      </FormControl>

                      <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        label="Discount Code"
                        name="discountCode"
                        value={pricingRuleForm.discountCode}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        placeholder="Discount Code"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  </fieldset>
                </Grid>

                {/*----- Duration Block -----*/}
                <Grid item xs={4}>
                  <fieldset
                    style={{
                      padding: "5px 20px 25px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Duration&nbsp;</legend>

                    <FormControl>
                      <RadioGroup
                        name="duration"
                        value={pricingRuleForm.duration}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          required
                          sx={{ mr: 4 }}
                          value="Permanent"
                          control={<Radio />}
                          label="Permanent"
                        />

                        <FormControlLabel
                          required
                          value="Temporary"
                          control={<Radio />}
                          label="Temporary"
                        />
                      </RadioGroup>
                    </FormControl>

                    {pricingRuleForm.duration === "Temporary" && (
                      <div>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // flexDirection: "column",
                          }}
                        >
                          <Typography minWidth={"35px"}>Start</Typography>
                          <DatePicker
                            sx={{ width: "100%" }}
                            format="MM/DD/YYYY"
                            value={startDate}
                            onChange={(newValue) =>
                              handleDateChange(newValue, "startDate")
                            }
                          />
                        </DemoContainer>

                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // flexDirection: "column",
                          }}
                        >
                          <Typography minWidth={"35px"}>End</Typography>
                          <DatePicker
                            sx={{ width: "100%" }}
                            format="MM/DD/YYYY"
                            value={endDate}
                            onChange={(newValue) =>
                              handleDateChange(newValue, "endDate")
                            }
                          />
                        </DemoContainer>
                      </div>
                    )}
                  </fieldset>
                </Grid>

                {/*----- Application Block -----*/}
                <Grid item xs={4}>
                  <fieldset
                    style={{
                      padding: "5px 20px 25px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Application&nbsp;</legend>
                    <Stack direction="row" spacing={1}>
                      <FormControl>
                        <RadioGroup
                          name="adjustmentLevel"
                          value={pricingRuleForm.adjustmentLevel}
                          onChange={handleApplicationChange}
                        >
                          <FormControlLabel
                            sx={{ mr: 4 }}
                            value="Item"
                            control={<Radio />}
                            label="Item"
                          />

                          <FormControlLabel
                            value="Order"
                            control={<Radio />}
                            label="Order"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Box>
                        <FormControlLabel
                          value="end"
                          name="stackable"
                          control={
                            <Checkbox
                              disabled={
                                pricingRuleForm.adjustmentLevel === "Order"
                              }
                              checked={pricingRuleForm.stackable}
                              onChange={handleChange}
                            />
                          }
                          label="Stackable"
                          labelPlacement="end"
                        />
                      </Box>
                    </Stack>
                  </fieldset>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/*---------- Order Eligibility Accordion ----------*/}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography sx={{ width: "20%", flexShrink: 0 }}>
                Order Eligibility
              </Typography>

              {/* <Typography sx={{ color: "text.secondary" }}>
                I am an accordion
              </Typography> */}

              <Stack direction="row" spacing={1}>
                {pricingRuleForm.dealership == true && (
                  <Chip
                    label="Dealership"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {dealerLocation.length > 0 && (
                  <Chip
                    label="Location: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.online == true && (
                  <Chip
                    label="Online"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {orderType.length > 0 && (
                  <Chip
                    label="Order Type: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.saleAmount > 0 && (
                  <Chip
                    label={`$ ${pricingRuleForm.saleAmount}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
              <Grid container rowSpacing={1} columnSpacing={2}>
                {/* <Grid item xs={12}>
                  <fieldset
                    style={{
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                    }}
                  >
                    <legend>&nbsp;Order Eligibility Summary&nbsp;</legend>

                    <Typography sx={{ p: 2 }} variant="h6">
                      Coming Soon
                    </Typography>
                  </fieldset>
                </Grid> */}

                {/*----- Sales Channel Block -----*/}
                <Grid item xs={4}>
                  <fieldset
                    style={{
                      padding: "5px 20px 80px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Sales Channel&nbsp;</legend>
                    <>
                      <FormControlLabel
                        name="dealership"
                        label="Dealership"
                        control={
                          <Checkbox
                            checked={pricingRuleForm.dealership}
                            onChange={handleChange}
                          />
                        }
                      />

                      <FormControl fullWidth sx={{ my: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Location
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          name="location"
                          multiple
                          disabled={pricingRuleForm.dealership}
                          value={dealerLocation}
                          onChange={handleDealerLocationChange}
                          input={<OutlinedInput label="Location" />}
                          renderValue={(selected) =>
                            selected
                              .map((dmsLocationId) => {
                                const selectedLocations = dealerLocations.find(
                                  (loc: any) =>
                                    loc.dmsLocationId === dmsLocationId,
                                )
                                return selectedLocations
                                  ? selectedLocations.name
                                  : ""
                              })
                              .join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {dealerLocations?.map((item: any) => (
                            <MenuItem
                              key={item.dmsLocationId}
                              value={item.dmsLocationId}
                            >
                              <Checkbox
                                checked={
                                  dealerLocation.indexOf(item.dmsLocationId) >
                                  -1
                                }
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControlLabel
                        name="online"
                        label="Online"
                        control={
                          <Checkbox
                            checked={pricingRuleForm.online}
                            onChange={handleChange}
                          />
                        }
                      />
                    </>
                  </fieldset>
                </Grid>

                {/*----- Order Type Block -----*/}
                <Grid item xs={4}>
                  <fieldset
                    style={{
                      padding: "5px 20px 165px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Order Type&nbsp;</legend>

                    <FormControl fullWidth sx={{ my: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Order type
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        name="orderType"
                        multiple
                        value={orderType}
                        onChange={handleOrderTypeChange}
                        input={<OutlinedInput label="Order type" />}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (value) =>
                                orderTypes.find((item) => item.enum === value)
                                  ?.name,
                            )
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {orderTypes.map((item) => (
                          <MenuItem key={item.enum} value={item.enum}>
                            <Checkbox
                              checked={orderType.indexOf(item.enum) > -1}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </fieldset>
                </Grid>

                {/*----- Order Amount Block -----*/}
                <Grid item xs={4}>
                  <fieldset
                    style={{
                      padding: "5px 20px 165px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Order Amount&nbsp;</legend>

                    <TextField
                      fullWidth
                      sx={{ mt: 2 }}
                      label="> ="
                      name="saleAmount"
                      disabled={pricingRuleForm.adjustmentLevel === "Item"}
                      value={pricingRuleForm.saleAmount}
                      onChange={handleChange}
                      type="number"
                      variant="outlined"
                      placeholder="Order Amount"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </fieldset>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/*---------- Customer Eligibility Accordion ----------*/}
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordionChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography sx={{ width: "20%", flexShrink: 0 }}>
                Customer Eligibility
              </Typography>

              <Stack direction="row" spacing={1}>
                {customerTypeState.length > 0 && (
                  <Chip
                    label="Customer Type: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {customerCat.length > 0 && (
                  <Chip
                    label="Category: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {customerPriceLvl.length > 0 && (
                  <Chip
                    label="Price Level: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {customers.length > 0 && (
                  <Chip
                    label="Customer(s): Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {saleVolumeCheck.saleCheckType && (
                  <Chip
                    label={formatUserName(saleVolumeCheck.saleCheckType)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {saleVolumeCheck.saleCheckAmount > 0 && (
                  <Chip
                    label={`$ ${saleVolumeCheck.saleCheckAmount}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {saleVolumeCheck.saleCheckDateOption != "None" && (
                  <Chip
                    label={`Date: ${saleVolumeCheck.saleCheckDateOption}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
              <Grid container rowSpacing={1} columnSpacing={2}>
                {/*----- Summary Block -----*/}
                {/* <Grid item xs={12}>
                  <fieldset
                    style={{
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                    }}
                  >
                    <legend>&nbsp;Customer Eligibility Summary&nbsp;</legend>

                    <Typography sx={{ p: 2 }} variant="h6">
                      Coming Soon
                    </Typography>
                  </fieldset>
                </Grid> */}

                {/*----- Customer Attributes Block -----*/}
                <Grid item xs={12}>
                  <fieldset
                    style={{
                      padding: "5px 20px 40px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Customer Attributes&nbsp;</legend>

                    <FormControl sx={{ width: 350, mr: 1 }}>
                      <InputLabel>Customer Type</InputLabel>
                      <Select
                        multiple
                        value={customerTypeState}
                        onChange={handleCustomerTypeChange}
                        input={<OutlinedInput label="Customer Type" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {customerType.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={customerTypeState.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ width: 350, mr: 1 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        {customerCat.length > 0
                          ? "Customer Category"
                          : "Customer Category - All"}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={customerCat}
                        onChange={handleCustomerCategoryChange}
                        input={
                          <OutlinedInput
                            label={
                              customerCat.length > 0
                                ? "Customer Category"
                                : "Customer Category - All"
                            }
                          />
                        }
                        renderValue={(selected) =>
                          selected
                            .map((categoryId) => {
                              const selectedCategory = customerCategory.find(
                                (category) =>
                                  category.customerCategoryId === categoryId,
                              )
                              return selectedCategory
                                ? selectedCategory.name
                                : ""
                            })
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {customerCategory.map((category) => (
                          <MenuItem
                            key={category.customerCategoryId}
                            value={category.customerCategoryId}
                          >
                            <Checkbox
                              checked={
                                customerCat.indexOf(
                                  category.customerCategoryId,
                                ) > -1
                              }
                            />
                            <ListItemText primary={category.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ width: 350, mr: 1 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        {customerPriceLvl.length > 0
                          ? "Customer Price Level"
                          : "Customer Price Level - All"}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={customerPriceLvl}
                        onChange={handleCustomerPriceLevelChange}
                        input={
                          <OutlinedInput
                            label={
                              customerPriceLvl.length > 0
                                ? "Customer Price Level"
                                : "Customer Price Level - All"
                            }
                          />
                        }
                        renderValue={(selected) =>
                          selected
                            .map((priceLevelId) => {
                              const ItemCategory = customerPriceLevels.find(
                                (priceLevel: any) =>
                                  priceLevel.customerPriceLevelId ===
                                  priceLevelId,
                              )
                              return ItemCategory
                                ? ItemCategory.description
                                : ""
                            })
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {customerPriceLevels.map((item: any) => (
                          <MenuItem
                            key={item.customerPriceLevelId}
                            value={item.customerPriceLevelId}
                          >
                            <Checkbox
                              checked={
                                customerPriceLvl.indexOf(
                                  item.customerPriceLevelId,
                                ) > -1
                              }
                            />
                            <ListItemText primary={item.description} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Stack direction="row" sx={{ mt: 5 }}>
                      <SearchableCustomerDD
                        customerType={customerTypeState}
                        customerCategory={customerCat}
                        customerPriceLevel={customerPriceLvl}
                        customers={customers}
                        setCustomers={setCustomers}
                      />
                    </Stack>
                  </fieldset>
                </Grid>

                {/*----- Sales Volume Block -----*/}
                <Grid item xs={12}>
                  <fieldset
                    style={{
                      padding: "5px 20px 20px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Sales Volume&nbsp;</legend>

                    <Stack direction="row" alignItems="center">
                      <TextField
                        sx={{ mt: 1, mr: 1, minWidth: "20%" }}
                        label="Customer Sales"
                        name="saleCheckType"
                        select
                        value={saleVolumeCheck.saleCheckType}
                        onChange={salesVolumeHandleChange}
                      >
                        {SaleCheckType.map((item) => (
                          <MenuItem key={item.enum} value={item.enum}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography mr={1}>greater than</Typography>

                      <TextField
                        sx={{ mt: 1, mr: 2, width: "15%" }}
                        // label="Amount"
                        name="saleCheckAmount"
                        variant="outlined"
                        placeholder="Amount"
                        value={saleVolumeCheck.saleCheckAmount}
                        onChange={salesVolumeHandleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        sx={{ mt: 1, mr: 2, width: 140 }}
                        label="Date Option"
                        name="saleCheckDateOption"
                        select
                        value={saleVolumeCheck.saleCheckDateOption}
                        onChange={salesVolumeHandleChange}
                      >
                        {CustomerSaleHistoryDateOption.map((item) => (
                          <MenuItem value={item.enum}>{item.name}</MenuItem>
                        ))}
                      </TextField>

                      {/* Date Section */}
                      {saleVolumeCheck.saleCheckDateOption != "None" && (
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Start Date"
                            sx={{ width: 200 }}
                            value={saleCheckStartDate}
                            onChange={(newValue) =>
                              handleSaleCheckDate(newValue, "startDate")
                            }
                          />

                          {saleVolumeCheck.saleCheckDateOption ===
                            "Between" && (
                            <DatePicker
                              label="End Date"
                              sx={{ width: 200 }}
                              value={saleCheckEndDate}
                              onChange={(newValue) =>
                                handleSaleCheckDate(newValue, "endDate")
                              }
                            />
                          )}
                        </DemoContainer>
                      )}
                    </Stack>

                    <fieldset
                      style={{
                        marginTop: "20px",
                        padding: "5px 20px 40px 20px",
                        fontSize: "small",
                        fontWeight: "bold",
                        border: "1px solid silver",
                        borderRadius: "10px",
                      }}
                    >
                      <legend>&nbsp;Product Options&nbsp;</legend>

                      <Stack direction="row" sx={{ mt: 1 }}>
                        <FormControl sx={{ mr: 2, width: 500 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Manufacturer
                          </InputLabel>
                          <Select
                            name="manufacturer"
                            multiple
                            value={saleVolumeManufacturerList}
                            onChange={handleSalesVolumeManufacturerChange}
                            input={<OutlinedInput label="Manufacturer" />}
                            disabled={
                              saleVolumeCheck.saleCheckType !=
                              "ProductSaleCheck"
                            }
                            renderValue={(selected) =>
                              selected
                                .map((manufacturerId) => {
                                  const selectedManufacturer =
                                    manufacturer.find(
                                      (mfr) =>
                                        mfr.manufacturerId === manufacturerId,
                                    )
                                  return selectedManufacturer
                                    ? selectedManufacturer.name
                                    : ""
                                })
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {manufacturer.map((item) => (
                              <MenuItem
                                key={item.manufacturerId}
                                value={item.manufacturerId}
                              >
                                <Checkbox
                                  checked={
                                    saleVolumeManufacturerList.indexOf(
                                      item.manufacturerId,
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl sx={{ mr: 2, width: 500 }}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Product Category
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            multiple
                            value={saleVolumeInventoryCat}
                            onChange={handleSalesVolumeInventoryCatChange}
                            input={<OutlinedInput label="Product Category" />}
                            disabled={
                              saleVolumeCheck.saleCheckType !=
                              "ProductSaleCheck"
                            }
                            renderValue={(selected) =>
                              selected
                                .map((categoryId) => {
                                  const selectedCategory =
                                    inventoryCategory.find(
                                      (category) =>
                                        category.categoryId === categoryId,
                                    )
                                  return selectedCategory
                                    ? selectedCategory.description
                                    : ""
                                })
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {inventoryCategory.map((item) => (
                              <MenuItem
                                key={item.categoryId}
                                value={item.categoryId}
                              >
                                <Checkbox
                                  checked={
                                    saleVolumeInventoryCat.indexOf(
                                      item.categoryId,
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={item.description} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>

                      <Stack direction="row" sx={{ mt: 5 }}>
                        <SearchableProductDD
                          disabled={
                            saleVolumeCheck.saleCheckType != "ProductSaleCheck"
                          }
                          // productType={productType}
                          manufacturerList={saleVolumeManufacturerList}
                          selectedProducts={saleVolumeSelectedProducts}
                          setSelectedProducts={setSaleVolumeSelectedProducts}
                        />
                      </Stack>
                    </fieldset>
                  </fieldset>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/*---------- Product Eligibility Accordion ----------*/}
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordionChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography sx={{ width: "20%", flexShrink: 0 }}>
                Product Eligibility
              </Typography>

              <Stack direction="row" spacing={1}>
                {/* {productType.length > 0 && (
                  <Chip
                    label="Product Type: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )} */}

                {inventoryCat.length > 0 && (
                  <Chip
                    label="Category: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {/* {manufacturerList.length > 0 && (
                  <Chip
                    label="Manufacturer: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )} */}

                {selectedProducts.length > 0 && (
                  <Chip
                    label="Products: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.disposition && (
                  <Chip
                    label={`Disposition: ${pricingRuleForm.disposition}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {selectedUnit.length > 0 && (
                  <Chip
                    label="Unit #: Multiselect"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.receivedItemDateOption && (
                  <Chip
                    label={`Received Date: ${pricingRuleForm.receivedItemDateOption}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
              <Grid container rowSpacing={1} columnSpacing={2}>
                {/*----- Summary Block -----*/}
                {/* <Grid item xs={12}>
                  <fieldset
                    style={{
                      // padding: "5px 10px 50px 10px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                    }}
                  >
                    <legend>
                      &nbsp;Product or Service Eligibility Summary&nbsp;
                    </legend>

                    <Typography sx={{ p: 2 }} variant="h6">
                      Coming Soon
                    </Typography>
                  </fieldset>
                </Grid> */}

                {/*----- Product Options Block -----*/}
                <Grid item xs={12}>
                  <fieldset
                    style={{
                      padding: "5px 20px 40px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Product Options&nbsp;</legend>

                    <Stack direction="row" sx={{ mt: 1 }}>
                      <FormControl sx={{ mr: 2, width: 342 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Product Type
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          name="productType"
                          multiple
                          disabled={pricingRuleForm.adjustmentLevel === "Order"}
                          value={productType}
                          onChange={handleProductTypeChange}
                          input={<OutlinedInput label="Product Type" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {productTypes.map((item) => (
                            <MenuItem key={item.enum} value={item.enum}>
                              <Checkbox
                                checked={productType.indexOf(item.enum) > -1}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl sx={{ mr: 2, width: 342 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Product Category
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          disabled={pricingRuleForm.adjustmentLevel === "Order"}
                          value={inventoryCat}
                          onChange={handleInventoryCategoryChange}
                          input={<OutlinedInput label="Product Category" />}
                          renderValue={(selected) =>
                            selected
                              .map((categoryId) => {
                                const selectedCategory = inventoryCategory.find(
                                  (category) =>
                                    category.categoryId === categoryId,
                                )
                                return selectedCategory
                                  ? selectedCategory.description
                                  : ""
                              })
                              .join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {inventoryCategory.map((item) => (
                            <MenuItem
                              key={item.categoryId}
                              value={item.categoryId}
                            >
                              <Checkbox
                                checked={
                                  inventoryCat.indexOf(item.categoryId) > -1
                                }
                              />
                              <ListItemText primary={item.description} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl sx={{ mr: 2, width: 342 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Manufacturer
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          name="manufacturer"
                          multiple
                          disabled={pricingRuleForm.adjustmentLevel === "Order"}
                          value={manufacturerList}
                          onChange={handleManufacturerChange}
                          input={<OutlinedInput label="Manufacturer" />}
                          renderValue={(selected) =>
                            selected
                              .map((manufacturerId) => {
                                const selectedManufacturer = manufacturer.find(
                                  (mfr) =>
                                    mfr.manufacturerId === manufacturerId,
                                )
                                return selectedManufacturer
                                  ? selectedManufacturer.name
                                  : ""
                              })
                              .join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {manufacturer.map((item) => (
                            <MenuItem
                              key={item.manufacturerId}
                              value={item.manufacturerId}
                            >
                              <Checkbox
                                checked={
                                  manufacturerList.indexOf(
                                    item.manufacturerId,
                                  ) > -1
                                }
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>

                    <Stack direction="row" sx={{ mt: 5 }}>
                      <SearchableProductDD
                        disabled={pricingRuleForm.adjustmentLevel === "Order"}
                        productType={productType}
                        manufacturerList={manufacturerList}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                      />
                    </Stack>
                  </fieldset>
                </Grid>

                {/*----- Wholegood Options Block -----*/}
                <Grid item xs={12}>
                  <fieldset
                    style={{
                      padding: "5px 20px 30px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Wholegood Options&nbsp;</legend>
                    <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                      <TextField
                        sx={{ mr: 2, width: 250 }}
                        label="Disposition"
                        name="disposition"
                        disabled={!isOnlyWholegoodInArray(productType)}
                        select
                        value={pricingRuleForm.disposition}
                        onChange={handleChange}
                      >
                        {/* <MenuItem value={""}>None</MenuItem> */}
                        {disposition.map((item) => (
                          <MenuItem value={item.enum}>{item.name}</MenuItem>
                        ))}
                      </TextField>

                      <SearchableUnitDD
                        disabled={!isOnlyWholegoodInArray(productType)}
                        inventoryPartIds={inventoryPartIds}
                        manufacturerList={manufacturerList}
                        selectedUnit={selectedUnit}
                        setSelectedUnit={setSelectedUnit}
                      />
                    </Stack>

                    <Stack direction="row" sx={{ mt: 5 }}>
                      <TextField
                        sx={{ mt: 1, mr: 2, width: 250 }}
                        label="Receive Date"
                        name="receivedItemDateOption"
                        disabled={!isOnlyWholegoodInArray(productType)}
                        select
                        value={pricingRuleForm.receivedItemDateOption}
                        onChange={wholegoodOptionsChangeHandler}
                      >
                        {/* <MenuItem value={""}>None</MenuItem> */}
                        {dateOptions.map((item) => (
                          <MenuItem value={item.enum}>{item.name}</MenuItem>
                        ))}
                      </TextField>

                      {/* Date Section */}
                      <Box sx={{ mr: 2 }}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{ mb: 1 }}
                        >
                          <DatePicker
                            label="Start Date"
                            sx={{ width: 250 }}
                            format="MM/DD/YYYY"
                            disabled={
                              pricingRuleForm.receivedItemDateOption == "" ||
                              !isOnlyWholegoodInArray(productType)
                            }
                            value={receivedItemStartDate}
                            onChange={(newValue) =>
                              handleReceiveDate(newValue, "startDate")
                            }
                          />
                        </DemoContainer>

                        {pricingRuleForm.receivedItemDateOption ==
                          "Between" && (
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="End Date"
                              sx={{ width: 250 }}
                              format="MM/DD/YYYY"
                              value={receivedItemEndDate}
                              onChange={(newValue) =>
                                handleReceiveDate(newValue, "endDate")
                              }
                            />
                          </DemoContainer>
                        )}
                      </Box>

                      <TextField
                        sx={{ mt: 1, mr: 2, width: 250 }}
                        label="Floor Plan Due Date"
                        name="floorPlanDueDate"
                        disabled={!isOnlyWholegoodInArray(productType)}
                        select
                        // value={pricingRuleForm}
                        // onChange={handleChange}
                      >
                        <MenuItem value={""}>Coming Soon!</MenuItem>
                        {/* {dateOptions.map((item) => (
                          <MenuItem value={item.enum}>{item.name}</MenuItem>
                        ))} */}
                      </TextField>

                      {/* Date Section */}
                      <Box>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            disabled
                            label="Start Date"
                            sx={{ width: 250 }}
                            // value={startDate}
                            // onChange={(newValue) =>
                            //   handleDateChange(newValue, "startDate")
                            // }
                          />
                        </DemoContainer>

                        {/* <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="End Date"
                            sx={{ width: 250 }}
                            value={endDate}
                            onChange={(newValue) =>
                              handleDateChange(newValue, "endDate")
                            }
                          />
                        </DemoContainer> */}
                      </Box>
                    </Stack>
                  </fieldset>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/*---------- Price Adjustment Accordion ----------*/}
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleAccordionChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography sx={{ width: "20%", flexShrink: 0 }}>
                Price Adjustment
              </Typography>

              <Stack direction="row" spacing={1}>
                {pricingRuleForm.adjustAmount > 0 && (
                  <Chip
                    label={`$ ${pricingRuleForm.adjustAmount}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.ruleType && (
                  <Chip
                    label={formatUserName(pricingRuleForm.ruleType)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.adjustPriceType && (
                  <Chip
                    label={pricingRuleForm.adjustPriceType}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {pricingRuleForm.adjustColumn && (
                  <Chip
                    label={pricingRuleForm.adjustColumn}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {/* {pricingRuleForm.quantityDiscountId && (
                  <Chip
                    label={pricingRuleForm.quantityDiscountId}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )} */}

                {pricingRuleForm.rounding != "None" && (
                  <Chip
                    label={formatUserName(pricingRuleForm.rounding)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </AccordionSummary>

            <Divider />

            <AccordionDetails>
              <Grid container rowSpacing={1} columnSpacing={2}>
                {/*----- Summary Block -----*/}
                {/* <Grid item xs={12}>
                  <fieldset
                    style={{
                      // padding: "5px 10px 30px 10px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      marginBottom: "10px",
                    }}
                  >
                    <legend>&nbsp;Price Adjustment Summary&nbsp;</legend>
                    <Typography sx={{ p: 2 }} variant="h6">
                      Coming Soon
                    </Typography>
                  </fieldset>
                </Grid> */}

                {/*----- Price Adjustment Block -----*/}
                <Grid item xs={12}>
                  <fieldset
                    style={{
                      padding: "5px 20px 40px 20px",
                      fontSize: "small",
                      fontWeight: "bold",
                      border: "1px solid silver",
                      borderRadius: "10px",
                    }}
                  >
                    <legend>&nbsp;Price Adjustment&nbsp;</legend>

                    <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        required
                        label="Amount"
                        name="adjustAmount"
                        type="text"
                        variant="outlined"
                        placeholder="Amount"
                        value={pricingRuleForm.adjustAmount}
                        onChange={handleNumberChange}
                        onKeyDown={handleKeyDown}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                        }}
                      />

                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        required
                        label="Rule Type"
                        name="ruleType"
                        select
                        value={pricingRuleForm.ruleType}
                        onChange={handleChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {ruleType.map((item) => (
                          <MenuItem key={item.enum} value={item.enum}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        required
                        label="Adjust Price Type"
                        name="adjustPriceType"
                        select
                        value={pricingRuleForm.adjustPriceType}
                        disabled={pricingRuleForm.ruleType == ruleType[2].enum}
                        onChange={handleChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {adjustPriceType.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        required
                        label="Pricing Column"
                        name="adjustColumn"
                        select
                        value={pricingRuleForm.adjustColumn}
                        disabled={pricingRuleForm.ruleType == ruleType[2].enum}
                        onChange={handleChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {pricingRuleForm.adjustmentLevel === "Order" ? (
                          <MenuItem value={"OrderSubTotal"}>
                            Order Sub-Total
                          </MenuItem>
                        ) : (
                          pricingColumns.map((item) => (
                            <MenuItem key={item.enum} value={item.enum}>
                              {item.name}
                            </MenuItem>
                          ))
                        )}
                      </TextField>
                    </Stack>

                    <Stack direction="row" sx={{ mt: 5 }}>
                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        name="quantityDiscountId"
                        select
                        disabled={pricingRuleForm.adjustmentLevel === "Order"}
                        label="Quantity Adjust Name"
                        value={pricingRuleForm.quantityDiscountId}
                        onChange={handleChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {quantityDiscounts?.data?.map((item: any) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        name="priceRangeId"
                        select
                        label="Profit Maximizer"
                        value={pricingRuleForm.priceRangeId}
                        onChange={handleChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {priceRanges?.map((item: any) => (
                          <MenuItem
                            key={item.priceRangeId}
                            value={item.priceRangeId}
                          >
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        sx={{ mr: 2, minWidth: "23.6%" }}
                        required
                        label="Rounding"
                        name="rounding"
                        select
                        disabled={pricingRuleForm.adjustmentLevel === "Order"}
                        value={pricingRuleForm.rounding}
                        onChange={handleChange}
                      >
                        {priceRuleRoundings.map((item: any) => (
                          <MenuItem key={item.enum} value={item.enum}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        sx={{ mr: 2, minWidth: "10.9%" }}
                        label="Round To"
                        name="roundTo"
                        type="text"
                        variant="outlined"
                        placeholder="Round To"
                        disabled={pricingRuleForm.rounding !== "Custom"}
                        value={customRounding.roundTo}
                        onChange={handleCustomRoundingChange}
                        onKeyDown={handleKeyDown}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                        }}
                      />

                      <TextField
                        sx={{ mr: 2, minWidth: "10.9%" }}
                        label="Subtract"
                        name="subtract"
                        type="text"
                        variant="outlined"
                        placeholder="Subtract"
                        disabled={pricingRuleForm.rounding !== "Custom"}
                        value={customRounding.subtract}
                        onChange={handleCustomRoundingChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    </Stack>
                  </fieldset>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </form>
      </Container>
    </>
  )
}

export default PricingRuleForm
