export const formatDateToLocalTime = (date: string | undefined) => {
  if (!date) return ""

  const formattedDate = new Date(
    date.replace("T", " ") + " UTC",
  ).toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })

  return formattedDate.replace(
    formattedDate.split(",")[0].split("/")[2], // year part
    new Date(date).getFullYear().toString(),
  )
}
