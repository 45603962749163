//---------- Accounts Endpoints ----------//
export const API_LOGIN = "Accounts/login"

//---------- Rules Settings Endpoints ----------//
export const API_LIST_PRICE_RULE = "ListPriceRule"
export const API_UPSERT_LIST_PRICE_RULE =
  "ListPriceRule/upsertDealerListPriceRules"
export const API_PRICING_COLUMN = "ListPriceRule/getPricingColumn"
export const API_PRICING_METHOD = "ListPriceRule/getPricingMethod"

//---------- Pricing Rules Endpoints ----------//
export const API_GET_RULES = "PricingRule/getRules"
export const API_GET_RULE_BY_ID = "PricingRule/getRule"
export const API_ADD_RULE = "PricingRule"
export const API_ARCHIVE_RULE = "PricingRule/archiveRule"
export const API_DELETE_RULE = "PricingRule"
export const API_UPDATE_RULE = "PricingRule"
export const API_GET_CUSTOMER_CATEGORY = "PricingRule/getCustomerCategory"
export const API_GET_MANUFACTURER = "PricingRule/getManufacturer"
export const API_GET_INVENTORY_CATEGORY = "PricingRule/getInventoryCategory"
export const API_GET_NET_PRICE = "PricingRule/getNetPrice"
export const API_GET_DEALER_LOCATIONS = "PricingRule/getDealerLocation"
export const API_GET_PART_MODEL = "PricingRule/getPartModel"
export const API_GET_UNIT = "PricingRule/getUnit"
export const API_GET_INVENTORY_FOR_DD =
  "https://csidealerdev.azurewebsites.net/api/Query"
export const API_GET_CUSTOMER_PRICE_LEVEL_FOR_DD =
  "PricingRule/getCustomerPriceLevel"
export const API_GET_PRICING_IMPORT_STATUS =
  "PricingRule/GetPricingImportStatus"
export const API_UPLOAD_PRICING_RULE_CSV = "PricingRule/uploadPricingRuleCSV"
export const API_GET_CUSTOMER = "PricingRule/getCustomer"

//---------- Quantity Discount Endpoints ----------//
export const API_GET_QUANTITY_DISCOUNTS_FOR_DD =
  "QuantityDiscount/GetQuantityDiscounts"
export const API_ADD_QUANTITY_DISCOUNT = "QuantityDiscount/AddQuantityDiscount"
export const API_UPDATE_QUANTITY_DISCOUNT =
  "QuantityDiscount/updateQuantityDiscountName"
export const API_DELETE_QUANTITY_DISCOUNT = "QuantityDiscount"
export const API_GET_QUANTITY_DISCOUNT_BY_ID =
  "QuantityDiscount/getQuantityDiscountsById"
export const API_ADD_QUANTITY_DISCOUNT_LEVEL =
  "QuantityDiscount/addQuantityDiscountLevel"
export const API_UPDATE_QUANTITY_DISCOUNT_LEVEL =
  "QuantityDiscount/updateQuantityDiscountLevel"
export const API_DELETE_QUANTITY_DISCOUNT_LEVEL =
  "QuantityDiscount/deleteQuantityDiscountLevel"
export const API_UPLOAD_QTY_DISC_CSV = "QuantityDiscount/uploadQtyDiscCSV"

//---------- Price Range Endpoints ----------//
export const API_GET_PRICE_RANGES = "PriceRange/getPriceRanges"
export const API_GET_PRICE_RANGES_FOR_DD = "PriceRange/GetPriceRanges"
export const API_ADD_PRICE_RANGE = "PriceRange/addPriceRange"
export const API_UPDATE_PRICE_RANGE = "PriceRange/updatePriceRange"
export const API_DELETE_PRICE_RANGE = "PriceRange"
export const API_GET_PRICE_RANGE_BY_ID = "PriceRange/getPriceRangeLevelsById"
export const API_ADD_PRICE_RANGE_LEVEL = "PriceRange/addPriceRangeLevel"
export const API_UPDATE_PRICE_RANGE_LEVEL = "PriceRange/updatePriceRangeLevel"
export const API_DELETE_PRICE_RANGE_LEVEL = "PriceRange/deletePriceRangeLevel"
export const API_UPLOAD_PRICE_RANGE_CSV = "PriceRange/uploadPriceRangeCSV"
