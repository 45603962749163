import { combineReducers } from "redux"
import { idmsApi } from "../services/api"

import authReducer from "../features/auth/authSlice"
import gridReducer from "../features/gridSlice/gridSlice"

export default combineReducers({
  [idmsApi.reducerPath]: idmsApi.reducer,

  auth: authReducer,
  grid: gridReducer,
})
