import { useState } from "react"
import { Box, IconButton, Toolbar, Typography } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuIcon from "@mui/icons-material/Menu"
import AppBar from "@mui/material/AppBar"
import AccountCircle from "@mui/icons-material/AccountCircle"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { logout } from "../../../features/auth/authSlice"
import { RootState } from "../../../app/store"
import { formatUserName } from "../../../helpers/helpers"

const drawerWidth = 240

const Header = (props: any) => {
  const dispatch = useAppDispatch()

  const username = useAppSelector((state: RootState) => state.auth.username)
  const companyName = useAppSelector(
    (state: RootState) => state.auth.companyName,
  )

  const { handleDrawerToggle } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body1" noWrap component="div">
            {`Welcome to TargetPrice - ${companyName}`}
          </Typography>
        </Box>

        <Typography variant="body1" noWrap component="div">
          {`Hello, ${formatUserName(username)}`}
        </Typography>

        <Box ml={1}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
