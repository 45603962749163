import {
  API_LIST_PRICE_RULE,
  API_UPSERT_LIST_PRICE_RULE,
} from "../ApiConstants"
import { idmsApi } from "../api"

const pricingApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getListPriceRule: builder.query<ListPriceRule[], ListPriceRuleRequest>({
      query: ({ DmsType, DmsDealerId }) => ({
        url: `${API_LIST_PRICE_RULE}?DmsType=${DmsType}&DmsDealerId=${DmsDealerId}`,
        method: "GET",
      }),
    }),

    upsertListPriceRule: builder.mutation<any, any>({
      query: (body) => ({
        url: API_UPSERT_LIST_PRICE_RULE,
        method: "POST",
        body,
      }),
    }),
  }),
})

export const { useGetListPriceRuleQuery, useUpsertListPriceRuleMutation } =
  pricingApi
