import { FC, useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Button, LinearProgress } from "@mui/material"
import CancelIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import {
  useGetPriceRangesQuery,
  useAddPriceRangeMutation,
  useUpdatePriceRangeMutation,
  useDeletePriceRangeMutation,
  useLazyGetPriceRangeByIdQuery,
  useAddPriceRangeLevelMutation,
  useUpdatePriceRangeLevelMutation,
  useDeletePriceRangeLevelMutation,
  useUploadPriceRangeCsvMutation,
  useGetPricingImportStatusQuery,
} from "../../../services/API/priceRangeAPI"
import AddPriceRangeDialogue from "./AddPriceRangeDialogue"
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import {
  getApiErrorMessage,
  getApplicationException,
} from "../../../utils/getApiErrorMessage"
import { findPriceRangeById } from "../../../helpers/helpers"
import FileUpload from "../../../components/common/FileUpload"

interface EditToolbarProps {
  priceRangeId: string
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void
  flag: boolean
  setFlag: (flag: boolean) => void
}

function EditToolbar(props: EditToolbarProps) {
  const { priceRangeId, setRows, setRowModesModel, flag, setFlag } = props
  const handleClick = async () => {
    try {
      const newRecord = {
        id: "",
        price: "",
        rangeAmount: "",
        rangeType: "",
        isNew: true,
      }

      // Update local state with the new record
      setRows((oldRows) => [...oldRows, newRecord])

      // Set row mode to Edit for the new record
      setRowModesModel((oldModel) => {
        const updatedModel = {
          ...oldModel,
          [newRecord.price]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "price",
          },
        }
        setFlag(false)

        return updatedModel
      })

      // Callback to notify the parent component about the new record
      // onAddRecord(newRecord)
    } catch (error) {
      // Handle any error that may occur during the addition of the record
      console.error("Error adding record:", error)
    }
  }

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button
        color="primary"
        startIcon={<AddIcon />}
        disabled={priceRangeId === "" || !flag}
        onClick={handleClick}
      >
        Add
      </Button>
    </GridToolbarContainer>
  )
}

const PriceRange: FC = () => {
  /*
   * Global States
   * */
  //debugger
  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )
  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )

  /*
   * Local States
   * */
  const [currentlyEditedRow, setCurrentlyEditedRow] =
    useState<GridRowId | null>(null)
  const [flag, setFlag] = useState(true)
  const [rows, setRows] = useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [isEditMode, setIsEditMode] = useState(false)
  const [priceRangeCode, setPriceRangeCode] = useState({
    priceRangeId: undefined,
    code: "",
    description: "",
  })
  const [isAddModalOpen, setAddModalOpen] = useState(false)
  const [priceRange, setPriceRange] = useState({
    dmsType,
    dmsDealerId,
    companyDealerId,
    priceRangeId: "",
    code: "",
    description: "",
    priceRangeLevels: [],
  })

  /*
   * API.
   * */

  const { data: priceRanges, refetch: refetchPriceRanges } =
    useGetPriceRangesQuery({
      DmsType: dmsType,
      DmsDealerId: dmsDealerId,
      CompanyDealerId: companyDealerId,
    })

  const [addPriceRange, { isLoading: addPriceRangeIsLoading }] =
    useAddPriceRangeMutation()

  const [updatePriceRange] = useUpdatePriceRangeMutation()

  const [deletePriceRange] = useDeletePriceRangeMutation()

  const [
    getPriceRangeLevels,
    { data: priceRangeLevelsData, isFetching: PrLevelsIsFetching },
  ] = useLazyGetPriceRangeByIdQuery()

  const [addPriceRangeLevel] = useAddPriceRangeLevelMutation()

  const [updatePriceRangeLevel] = useUpdatePriceRangeLevelMutation()

  const [deletePriceRangeLevel] = useDeletePriceRangeLevelMutation()

  const { data: pricingImportStatus = {} } = useGetPricingImportStatusQuery({
    companyDealerId,
  })

  /*
   * Effects
   */
  //Populate new grid data from priceRanges array
  // useEffect(() => {
  //   const fetchPrLevelsOnMount = async () => {
  //     if (priceRanges && priceRanges.data && priceRanges.data.length > 0) {
  //       // Initially select first option from PrDropdown
  //       // setPriceRange((prevState) => ({
  //       //   ...prevState,
  //       //   priceRangeId: priceRanges.data[0]?.priceRangeId,
  //       // }))

  //       // Fetch PriceRangeLevels against selected option
  //       await getPriceRangeLevels({
  //         id: priceRanges.data[0]?.priceRangeId,
  //       })
  //     }
  //   }

  //   fetchPrLevelsOnMount()
  // }, [priceRanges])

  useEffect(() => {
    if (priceRangeLevelsData) {
      setRows(priceRangeLevelsData)
    }
  }, [priceRangeLevelsData])

  /*
   * Handlers
   * */

  const handlePriceRangeChangeForGridClick = async (id: GridRowId) => {
    try {
      // Convert GridRowId to string
      const idAsString: string = id.toString()
      // Update the state with the clicked row's ID
      setPriceRange((prevState) => ({
        ...prevState,
        priceRangeId: idAsString,
      }))

      // Fetch PriceRangeLevels
      const response = await getPriceRangeLevels({ id })

      // Handle the error
      if ("error" in response) throw response

      // Check if data is empty
      if (!response.data || response.data.length === 0) {
        // Handle case when no data is found
        return // Exit function
      }

      // Data found, continue processing
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else if ((err as any)?.error?.status == 404) {
        toast.error("Price range not found.")
        // Handle the case when the price range is not found
        // For example, show a notification to the user
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleRowDoubleClick = (params: { id: GridRowId }) => {
    // Set flag to false when double-clicking on a row
    setFlag(false)
  }
  const handleAddButtonClick = () => {
    setIsEditMode(false)
    setAddModalOpen(true)
  }

  const handleUpdateButtonClick = () => {
    const PrObject = findPriceRangeById(priceRanges, priceRange.priceRangeId)
    if (PrObject) {
      setPriceRangeCode((prevState) => ({
        ...prevState,
        priceRangeId: PrObject.priceRangeId,
        code: PrObject.code,
        description: PrObject.description,
      }))

      setIsEditMode(true)
      setAddModalOpen(true)
    } else {
      toast.error("No Range Rule is selected to Edit.")
    }
  }

  const handleModalClose = () => {
    setAddModalOpen(false)

    // Make modal field empty
    setPriceRangeCode((prevState) => ({
      ...prevState,
      priceRangeId: undefined,
      code: "",
      description: "",
    }))
  }

  const addPriceRangeAsync = async () => {
    try {
      const res = await addPriceRange({
        dmsType,
        dmsDealerId,
        companyDealerId,
        code: priceRangeCode.code.trim(),
        description: priceRangeCode.description.trim(),
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Price Range Successfully Created")

      // Close modal
      setAddModalOpen(false)

      // Make modal field empty
      setPriceRangeCode((prevState) => ({
        ...prevState,
        priceRangeId: undefined,
        code: "",
        description: "",
      }))

      // Refetch Price Range
      refetchPriceRanges()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const updatePriceRangeAsync = async () => {
    try {
      const res = await updatePriceRange({
        priceRangeId: priceRangeCode.priceRangeId,
        body: {
          dmsType,
          dmsDealerId,
          companyDealerId,
          code: priceRangeCode.code.trim(),
          description: priceRangeCode.description.trim(),
        },
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Price Range successfully updated")

      // Close modal
      setAddModalOpen(false)

      // Make modal field empty
      setPriceRangeCode((prevState) => ({
        ...prevState,
        priceRangeId: undefined,
        code: "",
        description: "",
      }))

      // Refetch Price Range
      refetchPriceRanges()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const deletePriceRangeAsync = async (id: GridRowId) => {
    try {
      const res = await deletePriceRange({
        priceRangeId: id,
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Price Range successfully deleted")

      // Refetch Price Range
      refetchPriceRanges()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApplicationException(err))
      }
    }
  }

  const handleSaveButtonClick = async () => {
    if (isEditMode) {
      await updatePriceRangeAsync()
    } else {
      await addPriceRangeAsync()
    }
  }

  //----------------------------------------------//
  //              Data Grid Handlers             //
  //--------------------------------------------//

  function customeEditToolbar(props: EditToolbarProps) {
    return (
      <>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ justifyContent: "start", flexGrow: 1 }}>
            {/* {!pricingImportStatus?.priceRangeImport && ( */}
            <FileUpload
              useUploadMutation={useUploadPriceRangeCsvMutation}
              refetchData={refetchPriceRanges}
            />
            {/* )} */}
          </Box>

          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddButtonClick}
          >
            Add
          </Button>
        </GridToolbarContainer>
      </>
    )
  }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    setFlag(false)
  }
  const handleEditClickRange = (id: GridRowId) => async () => {
    try {
      const PrObject = findPriceRangeById(priceRanges, id)

      if (PrObject) {
        setPriceRangeCode((prevState) => ({
          ...prevState,
          priceRangeId: PrObject.priceRangeId,
          code: PrObject.code,
          description: PrObject.description,
        }))

        setIsEditMode(true)
        setAddModalOpen(true)
      } else {
        toast.error("No Range Rule is selected to Edit.")
      }
    } catch (err) {
      console.error("Error finding price range for edit:", err)
      toast.error("An error occurred while finding the price range to edit.")
    }
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    setFlag(true)
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handlePrLevelDelete(id)
          },
        },
        {
          label: "No",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const handleDeleteRange = (id: GridRowId) => async () => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deletePriceRangeAsync(id)
          },
        },
        {
          label: "No",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const handlePrLevelDelete = async (id: GridRowId) => {
    try {
      const res = await deletePriceRangeLevel({
        priceRangeId: priceRange.priceRangeId,
        id: id,
      })

      // Handle the error
      if ("error" in res) throw res

      // Handle the success response
      toast.success("Record Successfully Deleted")

      // ReFetch Price Range Levels
      await getPriceRangeLevels({ id: priceRange.priceRangeId })
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
    setFlag(true)
  }

  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      if (newRow.id === "") {
        // AddPriceRangeLevel API Call
        const res = await addPriceRangeLevel({
          priceRangeId: priceRange.priceRangeId,
          price: newRow.price,
          rangeAmount: newRow.rangeAmount,
          rangeType: newRow.rangeType,
        })

        // Handle the error
        if ("error" in res) throw res

        // Handle the success response
        toast.success("Discount Level Successfully Created")

        // ReFetch PriceRangeLevels
        await getPriceRangeLevels({ id: priceRange.priceRangeId })

        // Return the newly added object to grid
        return (res as any)?.data
      } else {
        // UpdatePriceRangeLevel API Call
        const res = await updatePriceRangeLevel({
          id: newRow.id,
          requestBody: {
            priceRangeId: priceRange.priceRangeId,
            price: newRow.price,
            rangeAmount: newRow.rangeAmount,
            rangeType: newRow.rangeType,
          },
        })
        // Handle the error
        if ("error" in res) throw res

        // Handle the success response
        toast.success("Price Range Level Successfully Updated")

        // ReFetch PriceRangeLevels
        await getPriceRangeLevels({ id: priceRange.priceRangeId })

        // Return the updated object to grid
        return (res as any)?.data
      }
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  // Columns for Profit Maximizer grid
  const newGridColumns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      align: "left",
      headerAlign: "left",
      sortable: false,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },

    {
      field: "actions",
      type: "actions",
      // headerName: "",

      flex: 1,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,

            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClickRange(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteRange(id)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  // Columns for Profit Maximizer Levels grid
  const columns: GridColDef[] = [
    // { field: "id", headerName: "Id", minWidth: 100, flex: 0.1 },
    {
      field: "price",
      headerName: "Dealer List Price >=",
      type: "number",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "rangeAmount",
      headerName: "Adjustment Amount",
      type: "number",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "rangeType",
      headerName: "Calc Type",
      minWidth: 100,
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { value: "Percentage", label: "Percentage" },
        { value: "Fixed", label: "Fixed" },
        { value: "Amount", label: "Amount" },
      ],
    },
    {
      field: "actions",
      type: "actions",
      // headerName: "",
      maxWidth: 80,
      flex: 0.1,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={!flag}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={!flag}
          />,
        ]
      },
    },
  ]

  const newGridData =
    priceRanges?.map((item: any) => ({
      id: item.priceRangeId,
      code: item.code,
      description: item.description ?? "",
    })) ?? []
  return (
    <>
      {PrLevelsIsFetching && <LinearProgress sx={{ mb: 1 }} />}

      {/* New DataGrid */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: 450,
            width: "45%",
            mb: 2,
            marginRight: "10px",
          }}
        >
          <DataGrid
            rows={newGridData}
            columns={newGridColumns}
            density="standard"
            disableColumnMenu
            disableColumnFilter
            onRowClick={(row) => handlePriceRangeChangeForGridClick(row.id)}
            hideFooterPagination={true}
            slots={{ toolbar: customeEditToolbar }}
          />
        </Box>

        {/* Existing DataGrid */}

        <Box
          sx={{
            height: 450,
            width: "55%",
            mb: 2,
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows || []}
            columns={columns}
            editMode="row"
            onRowDoubleClick={handleRowDoubleClick}
            density="standard"
            disableColumnMenu
            disableColumnFilter
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: EditToolbar }}
            slotProps={{
              toolbar: {
                priceRangeId: priceRange.priceRangeId,
                setRows,
                setRowModesModel,
                flag,
                setFlag,
              },
            }}
            // getRowId={(row) => row.priceRangeLevelId}
            onProcessRowUpdateError={(error) => {
              console.error("Error updating row:", error)
              // Handle the error here, e.g., show an error message to the user
              // You can also call any custom error handling function you have
            }}
          />
        </Box>
      </div>
      {/****** Add Dialog ******/}
      <AddPriceRangeDialogue
        isEditMode={isEditMode}
        isOpen={isAddModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveButtonClick}
        setPriceRangeCode={setPriceRangeCode}
        priceRangeCode={priceRangeCode}
        isLoading={addPriceRangeIsLoading}
      />
    </>
  )
}

export default PriceRange
