import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

interface AddDialogProps {
  isEditMode: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  priceRangeCode: {
    priceRangeId: undefined;
    code: string;
    description: string;
  };
  setPriceRangeCode: React.Dispatch<
    React.SetStateAction<{
      priceRangeId: undefined;
      code: string;
      description: string;
    }>
  >;
  isLoading: boolean;
}

const AddPriceRangeDialogue: React.FC<AddDialogProps> = ({
  isEditMode,
  isOpen,
  onClose,
  onSave,
  priceRangeCode,
  setPriceRangeCode,
  isLoading,
}) => {
  const isFieldsEmpty = priceRangeCode.code.trim() === '' || priceRangeCode.description.trim() === '';

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {isEditMode ? "Edit Price Range" : "Add Price Range"}
      </DialogTitle>
      <DialogContent>
        <TextField
          name="code"
          label="Code"
          value={priceRangeCode.code}
          onChange={(e) =>
            setPriceRangeCode((prevState) => ({
              ...prevState,
              code: e.target.value,
            }))
          }
          fullWidth
          size="small"
          margin="dense"
          required 
        />
        <TextField
          name="description"
          label="Description"
          value={priceRangeCode.description}
          onChange={(e) =>
            setPriceRangeCode((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
          fullWidth
          size="small"
          margin="dense"
          required 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave} color="primary" disabled={isLoading || isFieldsEmpty}>
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPriceRangeDialogue;
