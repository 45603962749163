import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../app/store"
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query"

const BaseUrl = import.meta.env.VITE_BASE_URL || ""

const baseQuery = fetchBaseQuery({
  baseUrl: BaseUrl,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const accessToken = (getState() as RootState).auth.access_token

    if (accessToken) {
      // headers.set("Content-Type", "application/json")
      headers.set("Authorization", `Bearer ${accessToken}`)
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  // if (result.error && [401, 500].includes(result.error.status as number)) {
  //   api.dispatch(logout());
  //   window.location.href = "/login";
  // }

  return result
}

export const idmsApi = createApi({
  reducerPath: "idmsApi",
  baseQuery: baseQueryWithReauth,
  // global configuration for the api
  // keepUnusedDataFor: 600,
  tagTypes: ["Auth"],
  endpoints: () => ({}),
})
