import { InputLabel, MenuItem, Select, SvgIcon } from "@mui/material"
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridDensity,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid"
import { setDensity, useDensity } from "../../features/gridSlice/gridSlice"
import { useAppDispatch } from "../../app/hooks"

export const CustomToolbar = () => {
  const density = useDensity()
  const dispatch = useAppDispatch()

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Select
        value={density}
        onChange={(event) =>
          dispatch(setDensity(event.target.value as GridDensity))
        }
        label="Density"
        variant="standard"
        disableUnderline
        IconComponent={() => null}
        renderValue={(value) => (
          <InputLabel
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#1976d2",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: 500,
              fontSize: "0.8125rem",
            }}
          >
            <SvgIcon
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              fontSize="small"
              style={{
                marginRight: 8,
              }}
            >
              {value === "compact" ? (
                <path d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"></path>
              ) : value === "standard" ? (
                <path d="M21,8H3V4h18V8z M21,10H3v4h18V10z M21,16H3v4h18V16z"></path>
              ) : (
                <path d="M4 18h17v-6H4v6zM4 5v6h17V5H4z"></path>
              )}
            </SvgIcon>
            DENSITY
          </InputLabel>
        )}
      >
        <MenuItem value="compact">
          <SvgIcon focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"></path>
          </SvgIcon>
          Compact
        </MenuItem>
        <MenuItem value="standard">
          <SvgIcon focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="M21,8H3V4h18V8z M21,10H3v4h18V10z M21,16H3v4h18V16z"></path>
          </SvgIcon>
          Standard
        </MenuItem>
        <MenuItem value="comfortable">
          <SvgIcon focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="M4 18h17v-6H4v6zM4 5v6h17V5H4z"></path>
          </SvgIcon>
          Comfortable
        </MenuItem>
      </Select>
    </GridToolbarContainer>
  )
}
