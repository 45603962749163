import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useLazyGetPartModelQuery } from "../../../services/API/pricingRuleAPI"

interface Props {
  disabled?: boolean
  productType?: string[]
  manufacturerList: string[]
  selectedProducts: PartsModels[]
  setSelectedProducts: Dispatch<SetStateAction<PartsModels[]>>
}

const SearchableProductDD = ({
  disabled = false,
  productType = [],
  manufacturerList = [],
  selectedProducts = [],
  setSelectedProducts,
}: Props) => {
  const [query, setQuery] = useState<string>("")
  const [options, setOptions] = useState<PartsModels[]>([])

  const companyDealerId = useAppSelector(
    (state: RootState) => state.auth.companyDealerId,
  )

  /*
   * API
   * */

  const [getPartModel, { data: partModels = [], isFetching }] =
    useLazyGetPartModelQuery()

  /*
   * Effects
   * */

  useEffect(() => {
    if (query.length >= 3) {
      getPartModel({
        CompanyDealerId: companyDealerId,
        ProductType: productType,
        Manufacturer: manufacturerList,
        Search: query,
      })
    }
  }, [query])

  // Update options whenever partModels changes
  useEffect(() => {
    setOptions(partModels)
  }, [partModels])

  /*
   * Handler
   * */

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: PartsModels[],
  ) => {
    setSelectedProducts(newValue)
  }

  return (
    <Autocomplete
      sx={{ width: "calc(100% - 15px)" }}
      multiple
      disabled={disabled}
      options={options}
      loading={isFetching}
      disableCloseOnSelect
      getOptionLabel={(option: PartsModels) =>
        option?.lineCode +
        " - " +
        option?.partNumber +
        " - " +
        option?.description
      }
      value={selectedProducts}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Product #"
          variant="outlined"
          onChange={handleInputChange}
        />
      )}
    />
  )
}

export default SearchableProductDD
