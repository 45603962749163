import AddIcon from "@mui/icons-material/Add"
import ArchiveIcon from "@mui/icons-material/Archive"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Container, IconButton, Stack, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import {
  GridActionsCellItem,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid"
import { FC, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import ServerGrid, { ServerGridProps } from "../../components/common/ServerGrid"
import { FilterOperator } from "../../constants/enums"
import {
  ADD_PRICING_RULE,
  UPDATE_PRICING_RULE,
} from "../../constants/routeConstants"
import {
  setGridPaginationModel,
  setPricingRulesColumnVisibilityModel,
  usePaginationModel,
  usePricingRulesColumnVisibilityModel,
} from "../../features/gridSlice/gridSlice"
import {
  useArchiveRuleMutation,
  useDeleteRuleMutation,
  useGetRulesQuery,
  useUploadPricingRuleCsvMutation,
} from "../../services/API/pricingRuleAPI"
import { formatDateToLocalTime } from "../../utils/formatDate"
import { getApiErrorMessage } from "../../utils/getApiErrorMessage"
import { getPageNumbers } from "../../utils/getPageNumbers"

import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import { ARCHIVE } from "../../constants/appConstants"
import { getPricingRuleColumns } from "../../utils/gridColumns/pricingRuleCoulmns"
import FileUpload from "./components/FileUpload"

const PricingRules: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const pricingRuleColumns = prepareColumns()

  const dmsType = useAppSelector((state: RootState) => state.auth.dmsType)
  const dmsDealerId = useAppSelector(
    (state: RootState) => state.auth.dmsDealerId,
  )
  const paginationModel = usePaginationModel()
  const setPaginationModel = (paginationModel: PaginationModel) =>
    dispatch(setGridPaginationModel(paginationModel))

  const onColumnVisibilityModelChange = (model: GridColumnVisibilityModel) =>
    dispatch(setPricingRulesColumnVisibilityModel(model))

  const columnVisibilityModel = usePricingRulesColumnVisibilityModel()

  const [rows, setRows] = useState<Rule[]>([])
  const [filterValue, setFilterValue] = useState("")
  const [filterOperator, setFilterOperator] = useState(FilterOperator.Equals)
  const [rowCountState, setRowCountState] = useState(0)

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const [filterActive, setFilterActive] = useState<boolean | null>(true)
  const [filterInactive, setFilterInactive] = useState<boolean | null>(null)
  const [filterArchive, setFilterArchive] = useState<boolean | null>(null)

  const [filter, setFilter] = useState<GetRulesSearchCriteria>({
    dmsType,
    dmsDealerId,
    ruleId: undefined,
    sortDir: undefined,
    sortField: undefined,
    isActive: true,
    isArchive: false,
    includeRuleDetails: true,
  })

  /*
   * API calls
   * */

  const {
    data: PricingRules = {},
    isFetching: PricingRulesIsFetching,
    isSuccess: PricingRulesIsSuccess,
    refetch: refetchPricingRules,
  } = useGetRulesQuery(
    {
      ...filter,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const [archiveRule] = useArchiveRuleMutation()
  const [deleteRule] = useDeleteRuleMutation()

  /*
   * Effects
   * */

  useEffect(() => {
    setRowCountState(PricingRules?.totalRecordsCount || 0)

    if (PricingRules?.data) {
      // Convert UTC date to local date time string
      const transformedRows = PricingRules?.data?.map((rule: any) => ({
        ...rule,
        id: rule.ruleId,
        startDateTime: rule.startDateTime
          ? formatDateToLocalTime(rule.startDateTime)
          : undefined,
        endDateTime: rule.endDateTime
          ? formatDateToLocalTime(rule.endDateTime)
          : undefined,
      }))

      setRows(transformedRows)
    }
  }, [PricingRulesIsSuccess, PricingRules])

  useEffect(() => {
    setRowCountState((prev: any) =>
      PricingRules?.totalRecordsCount !== undefined
        ? PricingRules?.totalRecordsCount
        : prev,
    )
  }, [paginationModel])

  /*
   * Handlers
   * */

  // Handles the click event for filter buttons
  const handleFilterClick = (filter: string | null) => {
    setFilterActive(filter === "active" ? true : null)
    setFilterInactive(filter === "inactive" ? false : null)
    setFilterArchive(filter === "archive" ? true : null)

    if (filter === "active") {
      setFilter((prevState: GetRulesSearchCriteria) => ({
        ...prevState,
        isActive: true,
        isArchive: false,
      }))
    }

    if (filter === "inactive") {
      setFilter((prevState: GetRulesSearchCriteria) => ({
        ...prevState,
        isActive: false,
        isArchive: false,
      }))
    }

    if (filter === "archive") {
      setFilter((prevState: GetRulesSearchCriteria) => ({
        ...prevState,
        isActive: false,
        isArchive: true,
      }))
    }
  }

  function prepareColumns() {
    const setActions = (params: GridRowParams<any>) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          title="Edit"
          onClick={() => {
            navigate(`${UPDATE_PRICING_RULE}/${params.row.ruleId}`)
          }}
          color="primary"
        />,

        <GridActionsCellItem
          icon={<ArchiveIcon />}
          label="Archive Toggle Button"
          title="Archive Toggle Button"
          onClick={() =>
            confirmationDialogueHandler(
              params.row.ruleId,
              ARCHIVE,
              params.row.archive,
            )
          }
          color="primary"
        />,
      ]
    }

    return getPricingRuleColumns(setActions)
  }

  const confirmationDialogueHandler = (
    id: any,
    name: string,
    isArchive?: boolean,
  ) => {
    confirmAlert({
      title: "Confirmation",
      message: isArchive
        ? "Are you sure you want to unarchive?"
        : "Are you sure you want to archive?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            name == ARCHIVE ? handleArchive(id, isArchive) : handleDelete(id)
          },
        },
        {
          label: "No",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const handleArchive = async (ruleId: any, isArchive?: boolean) => {
    try {
      let response = await archiveRule({
        archive: !isArchive,
        ruleIds: [ruleId],
      })

      // Handle the error
      if ("error" in response) throw response

      // Handle the success response
      toast.success(
        isArchive
          ? "Rule Unarchived Successfully"
          : "Rule Archived Successfully",
      )
      refetchPricingRules()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const handleDelete = async (ruleId: any) => {
    try {
      let response = await deleteRule([ruleId])

      // Handle the error
      if ("error" in response) throw response

      // Handle the success response
      toast.success("Rule Deleted Successfully")
      refetchPricingRules()
    } catch (err) {
      if ((err as any)?.error?.status == 401) {
        toast.error("User Unauthorized, Please Refresh the Page.")
      } else {
        toast.error(getApiErrorMessage(err))
      }
    }
  }

  const totalPages = Math.ceil(
    (PricingRules?.totalRecordsCount ?? rowCountState) /
      paginationModel.pageSize,
  )

  const pageNumbers = getPageNumbers(totalPages, paginationModel.page + 1)

  const onFilterChange = (model: GridFilterModel) => {
    // const newFilter: GetRulesSearchCriteria = {
    // ...(model.items.length > 0 && {
    //   id: model.items[0].field === "id" ? model.items[0].value : undefined,
    //   byName:
    //     model.items[0].field === "productName"
    //       ? model.items[0].value
    //       : undefined,
    // }),
    // }
    // setFilter(newFilter)
    // setPaginationModel({
    //   ...paginationModel,
    //   page: 0,
    // })
  }

  const handlePricingRuleFormClick = () => {
    navigate(ADD_PRICING_RULE)
  }

  const serverGridProps: ServerGridProps = {
    rows,
    columns: pricingRuleColumns,
    onFilterChange,
    paginationModel,
    rowCount: PricingRules?.totalRecordsCount ?? rowCountState ?? 0,
    setPaginationModel,
    rowSelectionModel,
    setRowSelectionModel,
    isLoading: PricingRulesIsFetching,
    keepNonExistentRowsSelected: true,
    pageNumbers,
    totalPages,
    filterOperator,
    setFilterOperator,
    filterValue,
    setFilterValue,
    hiddenColumns: ["ruleId", "startDateTime", "endDateTime"],
    disableColumnFilter: true,
    columnVisibilityModel,
    onColumnVisibilityModelChange,
  }

  return (
    <>
      <Helmet>
        <title> Pricing Rules </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" p={1} mb={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Pricing Rules
            </Typography>

            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button
                onClick={() => handleFilterClick("active")}
                variant={filterActive ? "contained" : "outlined"}
              >
                Active
              </Button>

              <Button
                onClick={() => handleFilterClick("inactive")}
                variant={
                  filterInactive !== null && !filterInactive
                    ? "contained"
                    : "outlined"
                }
              >
                Inactive
              </Button>

              <Button
                onClick={() => handleFilterClick("archive")}
                variant={filterArchive ? "contained" : "outlined"}
              >
                Archive
              </Button>
            </ButtonGroup>

            <Box>
              <FileUpload
                useUploadMutation={useUploadPricingRuleCsvMutation}
                refetchData={refetchPricingRules}
              />

              <IconButton
                title="Add new Quantity Discount"
                onClick={handlePricingRuleFormClick}
                sx={{
                  ml: 2,
                  p: 1,
                  color: "white",
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            </Box>
          </div>
        </Stack>

        <ServerGrid {...serverGridProps} />
      </Container>
    </>
  )
}

export default PricingRules
