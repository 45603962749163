import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: LoginResponse = {
  access_token: "",
  expires_in: 0,
  token_type: "",
  refresh_token: "",
  scope: "",
  dmsType: "",
  username: "",
  dealerId: "",
  dmsDealerId: "",
  companyDealerId: "",
  companyName: "",
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<LoginResponse>) => {
      state.access_token = action.payload.access_token
      state.expires_in = action.payload.expires_in
      state.refresh_token = action.payload.refresh_token
      state.token_type = action.payload.token_type
      state.scope = action.payload.scope
      state.username = action.payload.username
      state.dealerId = action.payload.dealerId
      state.dmsDealerId = action.payload.dmsDealerId
      state.companyDealerId = action.payload.companyDealerId
      state.companyName = action.payload.companyName
    },

    setAccessToken: (state, action: PayloadAction<any>) => {
      state.access_token = action.payload.access_token
    },

    setDmsType: (state, action: PayloadAction<any>) => {
      state.dmsType = action.payload.dmsType
    },

    logout: () => initialState,
  },
})

export const { setCredentials, setAccessToken, setDmsType, logout } =
  authSlice.actions
export default authSlice.reducer
