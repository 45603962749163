import { convertObjectToQueryParams } from "../../utils/convertObjectToQueryParams"
import {
  API_ADD_RULE,
  API_ARCHIVE_RULE,
  API_DELETE_RULE,
  API_GET_CUSTOMER,
  API_GET_CUSTOMER_CATEGORY,
  API_GET_CUSTOMER_PRICE_LEVEL_FOR_DD,
  API_GET_DEALER_LOCATIONS,
  API_GET_INVENTORY_CATEGORY,
  API_GET_MANUFACTURER,
  API_GET_NET_PRICE,
  API_GET_PART_MODEL,
  API_GET_PRICE_RANGES,
  API_GET_RULES,
  API_GET_RULE_BY_ID,
  API_GET_UNIT,
  API_UPDATE_RULE,
  API_UPLOAD_PRICING_RULE_CSV,
} from "../ApiConstants"
import { idmsApi } from "../api"

const pricingRuleApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getRules: builder.query<any, GetRulesSearchCriteria>({
      query: (getRulesSearchCriteria) => ({
        url: `${API_GET_RULES}?${convertObjectToQueryParams(
          getRulesSearchCriteria,
        )}`,
        method: "GET",
      }),
    }),

    getRuleById: builder.query<any, any>({
      query: (ruleId) => ({
        url: `${API_GET_RULE_BY_ID}/${ruleId}`,
        method: "GET",
      }),
    }),

    archiveRule: builder.mutation<any, any>({
      query: (body) => ({
        url: `${API_ARCHIVE_RULE}?archiveState=${body.archive}`,
        method: "POST",
        body: body.ruleIds,
      }),
    }),

    deleteRule: builder.mutation<any, any>({
      query: (body) => ({
        url: API_DELETE_RULE,
        method: "DELETE",
        body,
      }),
    }),

    getCustomerCategory: builder.query<CustomerCategory[], any>({
      query: ({ companyDealerId }) => ({
        url: `${API_GET_CUSTOMER_CATEGORY}?CompanyDealerId=${companyDealerId}`,
        method: "GET",
      }),
    }),

    getManufacturer: builder.query<Manufacturer[], any>({
      query: ({ companyDealerId }) => ({
        url: `${API_GET_MANUFACTURER}?CompanyDealerId=${companyDealerId}`,
        method: "GET",
      }),
    }),

    getInventoryCategory: builder.query<InventoryCategory[], any>({
      query: ({ companyDealerId }) => ({
        url: `${API_GET_INVENTORY_CATEGORY}?CompanyDealerId=${companyDealerId}`,
        method: "GET",
      }),
    }),

    addPricingRule: builder.mutation<any, AddRuleRequest>({
      query: (body) => ({
        url: API_ADD_RULE,
        method: "POST",
        body,
      }),
    }),

    updatePricingRule: builder.mutation<any, UpdateRuleRequest>({
      query: ({ ruleId, requestBody }) => ({
        url: `${API_UPDATE_RULE}/${ruleId}`,
        method: "PUT",
        body: requestBody,
      }),
    }),

    getNetPrice: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_NET_PRICE}?${convertObjectToQueryParams(request)}`,
        method: "GET",
      }),
    }),

    getDealerLocations: builder.query<any, any>({
      query: ({ companyDealerId }) => ({
        url: `${API_GET_DEALER_LOCATIONS}?CompanyDealerId=${companyDealerId}`,
        method: "GET",
      }),
    }),

    getPriceRanges: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_PRICE_RANGES}?${convertObjectToQueryParams(request)}`,
        method: "GET",
      }),
    }),

    getPartModel: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_PART_MODEL}?${convertObjectToQueryParams(request)}`,
        method: "GET",
      }),
    }),

    getUnit: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_UNIT}?${convertObjectToQueryParams(request)}`,
        method: "GET",
      }),
    }),

    getCustomerPriceLevel: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_CUSTOMER_PRICE_LEVEL_FOR_DD}?${convertObjectToQueryParams(
          request,
        )}`,
        method: "GET",
      }),
    }),

    uploadPricingRuleCsv: builder.mutation<any, any>({
      query: (body) => ({
        url: API_UPLOAD_PRICING_RULE_CSV,
        method: "POST",
        body,
      }),
    }),

    getCustomer: builder.query<any, any>({
      query: (request) => ({
        url: `${API_GET_CUSTOMER}?${convertObjectToQueryParams(request)}`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useGetRulesQuery,
  useGetRuleByIdQuery,
  useArchiveRuleMutation,
  useDeleteRuleMutation,
  useGetCustomerCategoryQuery,
  useGetManufacturerQuery,
  useGetInventoryCategoryQuery,
  useAddPricingRuleMutation,
  useUpdatePricingRuleMutation,
  useLazyGetNetPriceQuery,
  useGetDealerLocationsQuery,
  useGetPriceRangesQuery,
  useLazyGetPartModelQuery,
  useLazyGetUnitQuery,
  useGetCustomerPriceLevelQuery,
  useUploadPricingRuleCsvMutation,
  useLazyGetCustomerQuery,
} = pricingRuleApi
